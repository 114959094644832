<template>
    <div class="catalog" :class="is_loding ? 'loading' : ''">
        <catalogFilter @apply="changeFilters" @reset="resetFilters" />
        <catalogList :gifts="page_data.gifts" :in_catalog="true"/>
        <catalogPagination @change="changePage" :is_new_filter="is_new_filter" :count_pages="page_data.count_pages" :current_page="page_data.page" />
        <div class="catalog-loading"></div>
    </div>
</template>
    
<script>
import catalogApi from "@/assets/js/api/catalog.js"

import catalogList from "@/components/catalog/catalogList.vue"
import catalogFilter from "@/components/catalog/catalogFilter.vue"
import catalogPagination from "@/components/catalog/catalogPagination.vue"

import { mapState } from 'vuex'

export default {
    name: 'catalogMain',
    components: {
        catalogList,
        catalogFilter,
        catalogPagination
    },
    props: {
        moveTo: {
            type: Function
        },
    },
    data() {
        return {
            page_data: {
                gifts: [],
                count_pages: 1,
                page: 1,
            },
            filters: {},
            pagination: {
                page: 1,
                posts_per_page: 12,
            },
            is_loding: true,
            is_new_filter: false
        }
    },
    async created() {
        const json_filters = localStorage.getItem("filters");

        if (json_filters) {
            const local_filters = JSON.parse(json_filters);
            this.filters = local_filters;
        } else {
            this.filters = {};
        }
    },
    methods: {
        async changeFilters() {
            this.pagination.page = 1
            this.is_loding = true;

            const json_filters = localStorage.getItem("filters");
            let   filters = {}; 

            if (json_filters) {
                filters = JSON.parse(json_filters);
            }

            this.filters = filters;

            const gifts = await catalogApi.get(
                this.filters,
                this.pagination,
            );

            this.moveTo('.catalog');

            setTimeout(() => {
                if (gifts) {
                    this.page_data = gifts;
                }
            }, 1000) 

            this.is_new_filter = true
        },
        async resetFilters() {
            this.pagination.page = 1
            this.is_loding = true;

            localStorage.setItem("filters", "");
            this.filters = {};

            const gifts = await catalogApi.get(
                this.filters,
                this.pagination,
            );

            this.moveTo('.catalog');

            setTimeout(() => {
                if (gifts) {
                    this.page_data = gifts;
                }
            }, 1000) 

            this.is_new_filter = true
        },
        async changePage(page) {
            this.is_loding = true;
            this.pagination.page = page;
            localStorage.setItem('page', page)

            const json_filters = localStorage.getItem("filters");
            let local_filters = {};

            if (json_filters) {
                local_filters = JSON.parse(json_filters);
            }

            this.filters = local_filters;


            const gifts = await catalogApi.get(
                this.filters,
                this.pagination,
            );

            this.moveTo('.catalog');

            setTimeout(() => {
                if (gifts) {
                    this.page_data = gifts;
                }
            }, 1000) 
        },
    },
    computed: {
        // ...mapState({
        //     currentDistrict: (state) => state.currentDistrict,
        // }),
    },
    mounted() {
        if (localStorage.getItem('page')) {
            this.pagination.page = JSON.parse(localStorage.getItem('page'));
            this.current_page = JSON.parse(localStorage.getItem('page'));
        } else {
            this.pagination.page = 1
        }
        this.changePage(this.pagination.page);
    },
    watch: {
        page_data() {
            this.is_loding = false;
            this.$emit('update');
        }
    },
    emits: ['update']
}
</script>

<style>
.catalog {
    padding: 120px 0;
    position: relative;
}

.catalog-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    opacity: 0;
    pointer-events: none;
    z-index: 50;
    transition: 0.3s;
}

.catalog.loading .catalog-loading {
    opacity: 0.8;
    pointer-events: all;
}

.time {
    color: #8E141B;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
}
</style>