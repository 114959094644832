<template>
    <section id="wishes">
        <div class="container align-center">
            <h2>{{ data?.title }}</h2>
            <div class="wishes-cards">
                <div class="wishes-card left">
                    <div class="wishes-card-text">
                        <h3>{{ data?.material.title }}</h3>
                        <span v-html="data?.material.text"></span>
                    </div>
                    <img data-speed="1.2" class="wishes-card-img" src="@/assets/img/wishes/material.png" alt="">
                </div>
                <div class="wishes-card right">
                    <div class="wishes-card-text">
                        <h3>{{ data?.unmaterial.title }}</h3>
                        <span v-html="data?.unmaterial.text"></span>
                    </div>
                    <img data-speed="1.2" class="wishes-card-img" src="@/assets/img/wishes/unmaterial.png" alt="">
                </div>
            </div>
            <a class="btn" href="/catalog">Исполнить мечту</a>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Wishes',
    props: {
        data: null,
    }
}
</script>
<style>
#wishes h2 {
    color: var(--black);
    width: 100%;
    text-align: center;
    font-family: Mail Sans Roman;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 0 0 40px;
}

.wishes-cards {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 44px;
}

.wishes-card {
    position: relative;
    border-radius: 30px;
    border: none;
    padding: 40px;
    background: var(--blue);
    width: 100%;
    height: 590px;
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #FFF;
}

.wishes-card.left {
    background-image: url('../../assets/img/wishes/snow-left.png');
}

.wishes-card.right {
    background-image: url('../../assets/img/wishes/snow-right.png');
}

.wishes-card h3 {
    margin: 0 0 20px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}

.wishes-card-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    max-width: 380px;
}

.wishes-card-img {
    position: absolute;
    max-width: 100%;
    bottom: 60px;
    right: 0;
}

@media screen and (max-width: 1200px) {
    #wishes h2 {
        font-size: 24px;
        line-height: 100%;
        text-align: left;
        margin-bottom: 24px;
    }

    .wishes-cards {
        grid-template-columns: 1fr;
        gap: 16px;
    }
    
    .wishes-card {
        padding: 25px;
        height: 380px;
    }

    .wishes-card h3 {
        font-size: 20px;
        margin-bottom: 14px;
    }

    .wishes-card-text {
        font-size: 14px;
    }

    .wishes-card.left .wishes-card-img {
        max-width: 330px;
        right: 50%;
        transform: translateX(50%);
    }

    .wishes-card.right .wishes-card-img {
        max-width: 190px;
    }
}
</style>