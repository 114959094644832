<template>
    <div :class="'popup ' + ($store.state[type] ? 'active' : '')">
        <div class="popup-bg" @click="popupClose()"></div>
        <div class="popup-container">
            <div class="popup-header">
                <slot name="header">
                </slot>
                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" @click="popupClose()">
                    <path d="M1.58635 24.393L22.4137 1.26355" stroke="black" stroke-width="1.5" stroke-miterlimit="10"></path>
                    <path d="M1.3049 0.941525L22.1237 24.071" stroke="black" stroke-width="1.5" stroke-miterlimit="10"></path>
                </svg>
            </div>
            <div class="popup-content">
                <slot>

                </slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: '',
    },
    methods: {
        popupClose() { 
            this.$store.commit(this.type, false);
        },
    }
}
</script>
<style>
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100lvh;
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        transition: 0.8s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup.active {
        opacity: 1;
        pointer-events: all;
    }

    .popup-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 50;
    }

    .popup-container {
        position: relative;
        width: 500px;
        background-color: aliceblue;
        height: auto;
        z-index: 100;
        padding: 20px;
    }

    .popup-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .popup-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .popup-header svg {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
</style>