<template>
    <Page ref="page">
        <section id="not-found" class="first">
            <img class="not-found-bg" :src="require('@/assets/img/bg-404.png')" alt="">
            <div class="container container-404">
                <div class="img-404">
                    <img :src="require('@/assets/img/404.png')" alt="">
                </div>
                <div class="text-404">
                    <h1> 
                        <span>Ошибка!</span><br>
                        Страница была удалена, либо никогда не существовала. 
                    </h1>
                    <a href="/" class="btn-404">
                        Вернуться на главную
                    </a>
                </div>
            </div>
        </section>
    </Page>
</template>

<script>
    import {mapMutations} from 'vuex'
    import Page from '@/components/base/Page.vue';

    export default {
        components: {
            Page
        },
        methods: {
            ...mapMutations({
                setLoading: 'page/setLoading',
            }),
        },
        mounted() {
            this.$refs.page.init();
            this.setLoading(false);
        }
    }
</script>

<style scoped>
    #not-found {
        padding-bottom: 200px;
        position: relative;
    }

    h1 {
        color: #212121;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h1 span {
        color: #212121;
        font-size: 100px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
        margin-bottom: 10px;
    }

    .btn-404 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 550;
        line-height: 153%;
        text-transform: uppercase;
        border-radius: 20px;
        background: #145C8E;
        border: 1px solid #145C8E;
        padding: 27px;
        width: 100%;
        max-width: 619px;
        text-align: center;
        margin-top: 50px;
        transition: 0.8s;
    }

    .btn-404:hover {
        background: #fff;
        color: #145C8E;
    }

    .not-found-bg {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        z-index: -1;
    }

    .container-404 {
        padding-top: 88px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 44px;
    }

    .img-404 {
        width: 100%;
        height: auto;
    }

    .img-404 img {
        width: 100%;
        height: auto;
    }

    .text-404 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    @media screen and (max-width: 1200px) {

        #not-found {
            padding-bottom: 100px;
        }
        .container-404 {
            grid-template-columns: 1fr;
        }

        h1 {
            color: #212121;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h1 span {
            color: #212121;
            font-size: 50px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
        }

        .btn-404 {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
            text-transform: uppercase;
            width: 100%;
            padding: 17px;
            text-align: center;
        }

        .img-404 {
            display: flex;
            align-items: center;
        }

        .not-found-bg {
            object-fit: cover;
            width: auto;
        }
    }
</style>