import Api from "@/assets/js/api/index.js";

class PopupsApi extends Api {
    async get(data) {
        try {
            const result = await this.send('jwt-auth/v1/token', data, "POST");

            return result?.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async reg(data) {
        try {
            const result = await this.send('methods/register', data, "POST");

            return result?.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async rec(data) {
        try {
            const result = await this.send('methods/passReset', data, "POST");

            return result?.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async sucReg(data) {
        try {
            const result = await this.send('methods/', data, "POST");

            return result?.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async anon(data) {
        try {
            const result = await this.send('methods/present', data, "POST");

            return result?.data;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}

export default new PopupsApi();