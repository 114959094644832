<template>
    <section id="main">
        <div class="container">
            <div class="main-title" data-speed="0.6">
                <h1>
                    Самая тёплая<br>
                    акция на ямале
                </h1>
                <a class="btn" href="/catalog">Исполнить мечту</a>
            </div>
            <div class="main-imgs">
                <div class="main-img-container">
                    <img class="main-img-fon" src="@/assets/img/main/m-f-1.png" alt="">
                    <img class="main-img" data-speed="1.4" src="@/assets/img/main/m-i-1.png" alt="">
                </div>
                <div class="main-img-container">
                    <img class="main-img-fon" src="@/assets/img/main/m-f-2.png" alt="">
                    <img class="main-img" data-speed="1.4" src="@/assets/img/main/m-i-2.png" alt="">
                </div>
                <div class="main-img-container">
                    <img class="main-img-fon" src="@/assets/img/main/m-f-3.png" alt="">
                    <img class="main-img" data-speed="1.4" src="@/assets/img/main/m-i-3.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Main',
}
</script>
<style>
#main {
    position: relative;
}

#main h1 {
    color: var(--black);
    font-family: Mail Sans Roman;
    font-size: 90px;
    font-weight: 700;
    line-height: 99px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 100px #FFF;
    margin: 80px 0 40px;
}

.main-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.main-imgs {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    column-gap: 40px;
    margin-top: 24px;
}

.main-img-container {
    position: relative;
}

.main-img-container img {
    width: 100%;
    height: auto;
}

.main-img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 15;
}

@media screen and (max-width: 1200px) {
    #main {
        background-image: url('../../assets/img/main/main-m-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        position: relative;
    }

    #main h1 {
        font-size: 32px;
        font-style: normal;
        line-height: 100%;
        color: #FFF;
        margin-top: 140px;
        margin-bottom: calc(100vh - 400px);
    }

    .main-imgs {
        margin: 0 -20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        gap: 0;
        z-index: 5;
    }
    
    .main-img-fon,
    .main-img-container:nth-of-type(2) {
        display: none;
    }

    .main-img-container:nth-of-type(1) {
        margin-right: -20%;
    }

    .main-img-container:nth-of-type(3) {
        margin-left: -20%;
    }

    .main-img {
        position: relative;
    }
}
</style>