<template>
    <Page ref="page">
        <img class="contacts-bg desktop" src="@/assets/img/contacts-stars.png" alt="">
        <section id="contacts" class="first">
            <div class="container contacts-container">
                <h1 class="contacts-title">
                    <span>Контакты</span>
                </h1>
            </div>
            <div class="container">
                <div class="contacts">
                    <div v-html="page_data"></div>
                    <img class="contact-top desktop" src="@/assets/img/contact-top.png" alt="">
                    <img class="contact-bot desktop" src="@/assets/img/contact-bot.png" alt="">
                </div>
            </div>
        </section>
        <SVGMap ref="map"/>
    </Page>
</template>

<script>
import { mapMutations } from 'vuex'

import Page from '@/components/base/Page.vue'
import SVGMap from "@/components/Map.vue"

import ContactsApi from '@/assets/js/api/contacts.js'


export default {
    components: {
        Page,
        SVGMap
    },
    data() {
        return {
            page_data: {}
        }
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
    },
    async mounted() {
        let result = await ContactsApi.get();
        this.page_data = result.data.text

        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);
                
                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return Boolean(this.$refs.map); 
        }
    }
}
</script>

<style>
#contacts {
    position: relative;
    padding-top: 88px;
}

.contacts-title {
    color: #07395C;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    width: -moz-max-content;
    width: max-content;
    gap: 1vh;
    margin-bottom: 80px;
}

    .contacts-bg {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 11;
    }

h1 span {
    color: #07395C;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2vh #fff;
    width: -moz-max-content;
    width: max-content;
}

h1 span::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: #b4b4b4;
    right: 1vh;
    z-index: -1;
}

.contacts {
    position: relative;
    border-radius: 30px;
    border: 1px solid #828282;
    background: #F9F9F9;
    padding: 60px 50px;
    padding-bottom: 77px;
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: auto;
}

.contacts p {
    color: #212121;
    font-family: HelveticaNeueCyr;
    font-size: 36px;
    font-style: normal;
    font-weight: 550;
    line-height: 144%;
    max-width: 90%;
}

.contacts span {
    color: #212121;
    font-family: HelveticaNeueCyr;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
}

    .contact-bot,
    .contact-top {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

.contact-bot {
    bottom: 0px;
}

.contact-top {
    top: 0px;
}

@media screen and (max-width: 1200px) {
    .desktop {
        display: none;
    }

    .contacts-title {
        margin-bottom: 40px;
    }

    h1 span {
        color: #07395C;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
    }

    .contacts {
        padding: 0px;
        border: none;
        background: none;
    }

    .contacts p {
        color: #212121;
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        line-height: 144%;
        width: 100%;
    }

    .contacts span {
        color: #212121;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 144%;
    }
}
</style>