import Api from "./index.js";

class CatalogApi extends Api {
    async get (filters = {}, pagination = {}, district) {

        const default_pagination = {
            page: 1,
            posts_per_page: 12,
        }

        pagination = Object.assign(default_pagination, pagination);

        const data = {
            district,
            filters,
            pagination
        }
        
        const result = await this.send('methods/catalog', data, 'POST');
        console.log(result);
        return result?.data;
    }

    async getByID(id) {
        if (!id) {
            return false;
        }

        const result = await this.send('methods/catalog/' + id);
        return result?.data;
    }
}

export default new CatalogApi();