<template>
    <div class="container filter-container">
        <div class="popup-filter" :class="is_open_popup ? 'open' : ''">
            <div class="popup-bg-filter" @click="closePopup"></div>
            <form class="catalog-filter">
                <div class="close-btn">
                    <img src="@/assets/img/X.svg" alt="" @click="closePopup">
                </div>
                <div class="filter-item">
                    <div class="filter-item-head" :class="is_open_district ? 'active' : ''" @click="openDistrict">
                        <img class="logo-img" :src="district_img" alt="">
                        <span>{{ district_text }}</span>
                        <img class="img-arrow" src="@/assets/img/arrow.svg" alt="">
                    </div>
                    <div class="filter-item-drop-container" :class="is_open_district ? 'active' : ''">
                        <div class="filter-item-drop-list">
                            <div @click="setCurentDistrict(item.id, index)" class="filter-item-drop-district" v-for="(item, index) in district">
                                <div class="district-img">
                                    <img :src="item.emblem" alt="">
                                </div>
                                <p>{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-item">
                    <div class="filter-item-input">
                        <input type="checkbox" id="is_ordered" v-model="is_ordered" @change="change"/>
                        <label for="is_ordered">Забронированные подарки</label>
                    </div>
                    <div class="filter-item-input">
                        <input type="checkbox" id="is_free" v-model="is_free" @change="change"/>
                        <label for="is_free">Свободные подарки</label>
                    </div>
                    <div class="filter-item-input">
                        <input type="checkbox" id="all" v-model="all" @change="changeAll"/>
                        <label for="all">Все подарки</label>
                    </div>
                </div>
                <div class="filter-item">
                    <div class="filter-item-input">
                        <input type="checkbox" id="unmaterial" v-model="unmaterial"/>
                        <label for="unmaterial">Нематериальные подарки</label>
                    </div>
                    <div class="filter-item-input">
                        <input type="checkbox" id="material" v-model="material"/>
                        <label for="material">Материальные подарки</label>
                    </div>
                </div>
                <div class="filter-item filter-drop-item">
                    <div class="filter-drop-header" @click="openList">Категории получателей <img class="filter-drop-arrow" :class="is_open_list ? 'active' : ''" src="@/assets/img/arrow.svg" alt=""></div>
                    <div class="filter-drop" :class="is_open_list ? 'active' : ''">
                        <div class="filter-drop-container">
                            <div class="filter-item-input">
                                <input type="checkbox" id="children" v-model="children"/>
                                <label for="children">Дети</label>
                            </div>
                            <div class="filter-item-input">
                                <input type="checkbox" id="pensioners" v-model="pensioners"/>
                                <label for="pensioners">Пенсионеры</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-item">
                    <div class="reset-filter-btn" @click="setFilters">Применить фильтры</div>
                    <div class="reset-filter-btn" @click="resetFilters">Сбросить фильтры</div>
                </div>
            </form>
        </div>
        <div class="filter-popup-btn" @click="openPopup">
            <img src="@/assets/img/filter.png" alt=""> Фильтр
        </div>
    </div>
</template>
    
<script>
export default {
    name: 'catalogMain',
    components: {

    },
    data() {
        return {
            is_open_list:  false,
            is_open_popup: false,
            material:      false,
            unmaterial:    false,
            is_ordered:    false,
            is_free:       false,
            all:           false,
            children:      false,
            pensioners:    false,
            is_open_district: false,
            district_img: '',
            district_text: 'Выберите место исполнения желаний',
            district :     JSON.parse(sessionStorage.getItem('site_info')).map
        }
    },
    methods: {
        openList() {
            this.is_open_list = !this.is_open_list
        },
        openPopup() {
            this.is_open_popup = true
        },
        closePopup() {
            this.is_open_popup = false
        },
        openDistrict () {
            this.is_open_district = !this.is_open_district;
        },
        change() {
            if (this.is_ordered && this.is_free) {
                this.all = true;
            } else {
                this.all = false;
            }
        },
        changeAll() {
            if (this.all) {
                this.is_ordered = true;
                this.is_free    = true;
            } else {
                this.is_ordered = false;
                this.is_free    = false;
            }
        },
        setFilters() {
            let district
            this.is_open_district = false
            if (localStorage.getItem('setCurrentDistrict') == null) {
                district = 'none'
            } else if (localStorage.getItem('setCurrentDistrict') != '') {
                district = JSON.parse(localStorage.getItem('setCurrentDistrict')).id
            } else {
                district = 'none'
            }
            const filters = {
                material:   this.material,
                unmaterial: this.unmaterial,
                is_ordered: this.is_ordered,
                is_free:    this.is_free,
                children:   this.children,
                pensioners: this.pensioners,
                district:  district 
            };


            localStorage.setItem('filters', JSON.stringify(filters));
            localStorage.setItem('page', JSON.stringify(1));
            this.is_open_popup = false;

            this.$emit('apply');
        },
        resetFilters() {
            this.material   = false;
            this.unmaterial = false;
            this.is_ordered = false;
            this.is_free    = false;
            this.all        = false;
            this.children   = false;
            this.pensioners = false;
            this.district_text = 'Выберите место исполнения желаний';
            this.district_img = '';
            this.is_open_district = false
            localStorage.setItem('filters', '');
            localStorage.setItem("setCurrentDistrict", '');

            this.is_open_popup = false;

            this.$emit('reset');
        },
        setCurentDistrict(id, index) {
            let result = JSON.parse(sessionStorage.getItem('site_info'));
            this.$store.commit('setCurrentDistrict', result.map[index]);
            localStorage.setItem('setCurrentDistrict', JSON.stringify(result.map[index]));
            sessionStorage.setItem('site_info', JSON.stringify(result));
            this.$store.commit('site_info', result)
            this.$store.commit('openPopup', false)
            this.is_open_district = false
            this.district_img = result.map[index].emblem;
            this.district_text = result.map[index].name;
        },
    },
    created() {
        const local_filters = localStorage.getItem('filters');

        if (local_filters) {
            const filters = JSON.parse(local_filters);

            this.material   = filters.material;
            this.unmaterial = filters.unmaterial;
            this.is_ordered = filters.is_ordered;
            this.is_free    = filters.is_free;
            this.children   = filters.children;
            this.pensioners = filters.pensioners;
            this.all        = (filters.is_ordered && filters.is_free);
        }

        const local_dist = localStorage.getItem('setCurrentDistrict');

        if (local_dist) {
            const result = JSON.parse(local_dist);
            this.district_img = result.emblem
            this.district_text = result.name
        }

    },
    emits: ["reset","apply"]
}
</script>
<style>
       .filter-popup-btn,
    .close-btn {
        display: none;
    }

    .filter-item-head {
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: var(--red);
        border: 2px solid var(--red);
        padding: 8px;
        border-radius: 18px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-items: center;
        cursor: pointer;
    }

    .img-arrow {
        transition: 0.5s;
    }

    .filter-item-head.active .img-arrow {
        transform: rotate(180deg);
    }
    

    .filter-item-head .logo-img {
        height: 26px;
    }

    .filter-item-drop-district {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
    }

    .district-img {
        width: 26px;
        height: 26px;
    }

    .filter-item-drop-district p {
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 146.9%;
    }

    .filter-item-drop-district img {
        height: 26px;
    }

    .filter-item-drop-container {
        display: grid;
        grid-template-rows: 0fr;
        overflow: auto;
        transition: 0.8s;
        position: absolute;
        left: 0px;
        top: 52px;
        background-color: #FFF;
        z-index: 1000;
        padding: 0px 10px;
        max-height: 50vh;
    }

    .filter-item-drop-container.active {
        grid-template-rows: 1fr;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: none;
    }

    .filter-item-drop-list {
        min-height: 0px;
    }

    .filter-container {
        /* max-width: 1840px; */
    }

    .catalog-filter {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.10);
        padding: 25px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        transition: none;
    }

    .filter-item {
        position: relative;
        width: max-content;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .filter-item-input {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .filter-item-input label {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .filter-item-input input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .filter-item-input input+label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .filter-item-input input+label::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 25px;
        border: 2px solid #424242;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }

    .filter-item-input input:checked+label::before {
        background-image: url("@/assets/img/cheked.svg");
    }

    .filter-drop-header {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .filter-drop-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;
        cursor: pointer;
    }

    .filter-drop-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 0px;
    }

    .filter-drop {
        display: grid;
        grid-template-rows: 0fr;
        transition: 0.8s;
        overflow: hidden;
    }

    .filter-drop.active {
        grid-template-rows: 1fr;
    }

    .filter-drop-arrow {
        transition: 0.8s;
    }

    .filter-drop-arrow.active {
        transform: rotate(180deg);
    }

    .reset-filter-btn {
        color: #145C8E;
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        line-height: 153%;
        text-transform: uppercase;
        border-radius: 20px;
        border: 2px solid #145C8E;
        background: #FFF;
        padding: 8px;
        width: 100%;
        text-align: center;
        cursor: pointer;
        transition: 0.8s;
    }

    .reset-filter-btn:hover {
        background-color: #145C8E;
        color: #FFF;
    }

    @media screen and (max-width: 1650px) {
        .catalog-filter {
            grid-template-columns: repeat(4, 1fr) 230px;
        }
        
        .filter-item-input label,
        .filter-drop-header,
        .filter-item-head {
            font-size: 18px;
        }

        .reset-filter-btn {
            font-size: 15px;
            padding: 10px;
        }

        .filter-item-input input+label::before {
            margin-right: 15px;
            width: 18px;
            height: 18px;
        }
    }

    @media screen and (max-width: 1200px) {

        .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    
        .catalog-filter {
            grid-template-columns: 1fr;
            gap: 0px;
        }
    
        .product-list {
            grid-template-columns: 1fr;
            gap: 50px;
        }
    
        .filter-item-input label, 
        .filter-drop-header {
            font-size: 18px;
        }
    
        .filter-item-input input+label::before {
            margin-right: 15px;
            width: 20px;
            height: 20px;
        }
    
        .filter-item {
            padding: 20px 0px;
            gap: 20px;
        }
    
        .filter-drop-container {
            gap: 20px;
        }
        
        .filter-item:nth-child(1),
        .filter-item:nth-child(2) {
            border-bottom: 1px solid #4A4A4A;
        }
    
        .popup-filter {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
            transition: 0.8s;
            z-index: 1000;
        }
    
        .popup-filter.open {
            opacity: 1;
            pointer-events: all;
        }
    
        .popup-bg-filter {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.40);
        }
    
        .catalog-filter {
            position: fixed;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;
            width: calc(100% - 40px);
            height: auto;
            max-height: 80lvh;
            overflow: auto;
        }

        .filter-popup-btn {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            width: max-content;
            margin-left: auto;
            cursor: pointer;
        }

        .filter-drop-item {
            height: 172px;
        }

        .filter-item-drop-container {
            position: relative;
            top: 0px;
            max-height: 100%;
        }
    }

</style>