<template>
    <Page ref="page">
        <div class="lk" v-if="$store.state.token != null">
            <div class="lk-header">
                <h1>
                    <span>
                        личный кабинет
                    </span>
                </h1>
                <a class="settings-btn" href="/settings">
                    <span>
                        настройки
                    </span>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z"
                            stroke="#236695" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M2.5 16.1008V13.9008C2.5 12.6008 3.5625 11.5258 4.875 11.5258C7.1375 11.5258 8.0625 9.92578 6.925 7.96328C6.275 6.83828 6.6625 5.37578 7.8 4.72578L9.9625 3.48828C10.95 2.90078 12.225 3.25078 12.8125 4.23828L12.95 4.47577C14.075 6.43828 15.925 6.43828 17.0625 4.47577L17.2 4.23828C17.7875 3.25078 19.0625 2.90078 20.05 3.48828L22.2125 4.72578C23.35 5.37578 23.7375 6.83828 23.0875 7.96328C21.95 9.92578 22.875 11.5258 25.1375 11.5258C26.4375 11.5258 27.5125 12.5883 27.5125 13.9008V16.1008C27.5125 17.4008 26.45 18.4758 25.1375 18.4758C22.875 18.4758 21.95 20.0758 23.0875 22.0383C23.7375 23.1758 23.35 24.6258 22.2125 25.2758L20.05 26.5133C19.0625 27.1008 17.7875 26.7508 17.2 25.7633L17.0625 25.5258C15.9375 23.5633 14.0875 23.5633 12.95 25.5258L12.8125 25.7633C12.225 26.7508 10.95 27.1008 9.9625 26.5133L7.8 25.2758C6.6625 24.6258 6.275 23.1633 6.925 22.0383C8.0625 20.0758 7.1375 18.4758 4.875 18.4758C3.5625 18.4758 2.5 17.4008 2.5 16.1008Z"
                            stroke="#236695" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </a>
            </div>
            <History ref="history"/>
        </div>
        <div v-else class="container logout">
            <span>
                Вы не авторизованы. <br>
                Пожалуйста войдите в аккаунт.
            </span>
            <div class="btn" @click="openAvtoriz">Войти</div>
        </div>
        <SVGMap ref="map" />
        <RForm ref="form"/>
    </Page>
</template>

<script>
import Page from "@/components/base/Page.vue"
import History from "@/components/lk/History.vue"
import SVGMap from "@/components/Map.vue"
import RForm from "@/components/Form.vue"

import { mapMutations } from 'vuex'

export default {
    components: {
        Page,
        History,
        SVGMap,
        RForm,
    },
    data() {
        return {
            smoother: null,
        }
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);
                
                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return ( 
                this.$refs.history && 
                this.$refs.map && 
                this.$refs.form
            ); 
        }
    }
}
</script>

<style>
.lk {
    padding: 200px 40px;
}

.lk-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lk-header h1 {
    font-family: HelveticaNeueCyrBlack;
    font-size: 60px;
    color: #07395C;
    text-transform: uppercase;
}

.settings-btn {
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 15px 40px;
    border: 2px solid #145C8E;
    border-radius: 20px;
}

.settings-btn span {
    font-size: 20px;
    color: #145C8E;
    margin-top: 6px;
    text-transform: uppercase;
}
</style>