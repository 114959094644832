<template>
    <Header :moveTo="moveTo"/>
    <div class="scroll-wrapper">
        <div class="scroll-content">
            <slot></slot>
            <Footer :moveTo="moveTo"/>
        </div>
    </div>
    <Popups/>
    <CuratorsPopup/>
</template>
    
<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from '@/assets/js/libs/SmothScroller.js';

import Header from '@/components/Header.vue';
import Footer from "@/components/Footer.vue";
import Popups from '@/components/popups/common-popup.vue';
import CuratorsPopup from '../home/CuratorsPopup.vue';

export default {
    name: 'Page',
    components: {
        Header,
        Footer,
        Popups,
        CuratorsPopup
    },
    data() {
        return {
            smoother: null,
        }
    },
    methods: {
        moveTo(selector) {
            if (this.smoother) {
                this.smoother.scrollTo(selector, true, "top 100px");
            }
        },
        init() {
            gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

            if (ScrollTrigger.isTouch !== 1) {
                this.smoother = ScrollSmoother.create({
                    wrapper: '.scroll-wrapper',
                    content: '.scroll-content',
                    smooth: 3,
                    effects: true,
                });

                if (this.$route.query?.section) {
                    setTimeout(() => {
                        this.smoother.scrollTo('#' + this.$route.query?.section, true, "top 100px");
                    }, 1000);
                }
            }
        },
        resetScroll() {
            if (ScrollTrigger.isTouch !== 1) {
                this.smoother.kill();

                this.smoother = ScrollSmoother.create({
                    wrapper: '.scroll-wrapper',
                    content: '.scroll-content',
                    smooth: 3,
                    effects: true,
                });
            }
        }
    },
}
</script>