<template>
    <Page ref="page">
        <div class="container gift-container">
            <img class="gift-star" src="@/assets/img/stars/star1.svg" alt="">
            <img class="gift-star" src="@/assets/img/stars/star2.svg" alt="">
            <img class="gift-star" src="@/assets/img/stars/star3.svg" alt="">
            <img class="gift-star" src="@/assets/img/stars/star4.svg" alt="">
            <img class="gift-star" src="@/assets/img/stars/star5.svg" alt="">
            <img class="gift-star" src="@/assets/img/stars/star6.svg" alt="">
            <a href="/catalog" class="back-btn">
                <img src="@/assets/img/arrow-back.svg" alt="">
                    Вернуться в каталог
            </a>
            <div class="gift-section">
                <div class="circles">
                    <img v-if="age >= 21" src="@/assets/img/old.svg" alt="">
                    <img v-else-if="gender == 'man'" src="@/assets/img/man.svg" alt="">
                    <img v-else-if="gender == 'woman'" src="@/assets/img/woman.svg" alt="">
                    <div class="circle3"></div>
                    <div class="circle4"></div>
                </div>
                <div class="gift-text-img">
                    <div class="gift-text">
                        <h2>{{ name.trim() }}, {{ ageText }}</h2>
                        <div class="city-children">
                            <img :src="district_img" alt="">
                            {{ district }}
                        </div>
                        <p v-html="about"></p>
                        <div v-if="!is_ordered" @click="booked" class="gift-btn desktop">
                            забронировать подарок
                        </div>
                        <div v-else class="gift-ordered-text desktop">
                            <img v-if="ordered_img" :src="ordered_img" alt="">
                            <span v-html="ordered_text"></span>
                        </div>
                    </div>
                    <div class="gift-img">
                        <span v-html="text"></span>
                        <img v-if="img" :src="img" alt="">
                        <div v-if="!is_ordered" @click="booked" class="gift-btn mobile">
                            забронировать подарок
                        </div>
                        <div v-else class="gift-ordered-text mobile">
                            <img v-if="ordered_img" :src="ordered_img" alt="">
                            <span v-html="ordered_text"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SVGMap ref="map"/>
        <RForm  ref="form"/>
    </Page>
</template>
    
<script>
import catalogApi from "@/assets/js/api/catalog.js"

import { num_word } from '@/assets/js/helper.js';

import Page from "@/components/base/Page.vue";
import SVGMap from "@/components/Map.vue"
import RForm from "@/components/Form.vue"

import { mapMutations } from 'vuex'

export default {
    name: 'GiftView',
    components: {
        Page,
        SVGMap,
        RForm,
    },
    data() {
        return {
            name:         'Имя',
            age:          1,
            age_month:    2,
            gender:       'man',
            about:        '',
            img:          '',
            text:         '',
            is_ordered:   false,
            ordered_text: '',
            ordered_img:  '',
            district:     '',
            district_img: ''
        }
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
        booked() {
            this.$store.commit('setGiftId', this.$route.params.id);
            this.$store.commit('setIsGift', true);
            if (localStorage.getItem("token")) {
                this.$store.commit('setTypePopup', 'anonymity')
                this.$store.commit('openPopup', true);
            } else {
                this.$store.commit('setTypePopup', 'avtoriz')
                this.$store.commit('openPopup', true);
            }
        // TODO check auth and open popup
        },  
    },
    async mounted() {
        const data = await catalogApi.getByID(this.$route.params.id);

        if (data) {
            this.name         = data.name;
            this.age          = data.age;
            this.age_month    = data.age_month;
            this.gender       = data.gender;
            this.about        = data.about;
            this.img          = data.img;
            this.text         = data.text;
            this.is_ordered   = data.is_ordered;
            this.ordered_text = data?.ordered_text;
            this.ordered_img  = data?.ordered_img;
            this.district     = data.city_title;
            this.district_img = data.city_img;
        }
        
        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);
                
                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        ageText() {
            let text = '';
            
            if (this.age) {
                text += num_word(this.age, ['год', 'года', 'лет'], true) + ' ';
            } 

            if (this.age_month) {
                text += num_word(this.age_month, ['месяц', 'месяца', 'месяцев'], true);
            }

            return text;
        },
        is_ready() {
            return ( 
                this.$refs.map && 
                this.$refs.form
            ); 
        }
    }
}
</script>
<style>
* {
    text-decoration: none;
}

.back-btn {
    position: relative;
    color: #4A4A4A;
    font-size: 28px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    background-color: #fff;
    cursor: pointer;
    width: max-content;
    z-index: 10;
}

.gift-container {
    position: relative;
    padding: 120px 10px;
}

.gift-star {
    position: absolute;
    top: 0px;
    z-index: 1;
}

.gift-star:nth-child(1) {
    left: 64px;
}

.gift-star:nth-child(2) {
    left: 172px;
}

.gift-star:nth-child(3) {
    left: 241px;
}

.gift-star:nth-child(4) {
    right: 232px;
}

.gift-star:nth-child(5) {
    right: 143px;
}

.gift-star:nth-child(6) {
    right: 35px;
}

.gift-section {
    margin-top: 70px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.10);
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.circles {
    position: relative;
    width: 418px;
    height: 327px;
    overflow: hidden;
}

.circles img {
    position: absolute;
    top: 95px;
    left: 257px;
    z-index: 10;
}

.circle3 {
    top: -124px;
    left: -43px;
    position: absolute;
    width: 355px;
    height: 354px;
    flex-shrink: 0;
    border-radius: 355px;
    border: 1px solid #4A4A4A;
}

.circle4 {
    top: -203px;
    left: -112px;
    position: absolute;
    width: 529px;
    height: 529px;
    flex-shrink: 0;
    border-radius: 529px;
    border: 1px solid #4A4A4A;
}

.gift-text-img {
    display: flex;
    flex-direction: row;
    gap: 41px;
    padding: 40px;
}

.gift-text h2 {
    color: #264E6B;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
    margin-bottom: 15px;
}

.gift-text p {
    color: #4A4A4A;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 146.9%;
    margin: 0px;
    margin-bottom: 20px;
    min-height: 110px;
}

.gift-btn {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
    border-radius: 20px;
    background: var(--blue);
    border: 2px solid var(--blue);
    cursor: pointer;
    padding: 13px;
    width: 100%;
    text-align: center;
    transition: 0.8s;
}

.gift-btn:hover {
    background: #FFF;
    color: var(--blue);
}

.gift-img {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.gift-img span {
    color: #4A4A4A;
    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 146.9%;
}

.gift-text,
.gift-img {
    width: 100%;
}

.gift-img img {
    width: 100%;
    height: auto;
}

.gift-btn.mobile {
    display: none;
}

.gift-ordered-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gift-ordered-text img {
    width: 100%;
    max-width: 100px;
}

.gift-ordered-text.mobile {
    display: none;
}

@media screen and (max-width: 1200px) {

    .gift-ordered-text.mobile {
        display: flex;
    }

    .gift-ordered-text.desktop {
        display: none;
    }

    .gift-star {
        display: none;
    }

    .back-btn {
        font-size: 18px;
        gap: 10px;
    }

    .back-btn img {
        width: 6px;
        height: 13px;
    }

    .circle3 {
        width: 135px;
        height: 135px;
        flex-shrink: 0;
        border: 1px solid #4A4A4A;
        border-radius: 50%;
        position: absolute;
        top: -46px;
        left: -40px;
    }

    .circle4 {
        width: 202px;
        height: 202px;
        flex-shrink: 0;
        border: 1px solid #4A4A4A;
        border-radius: 50%;
        position: absolute;
        top: -76px;
        left: -67px;
    }

    .circles img {
        top: 36px;
        left: 38px;
        transform: scale(0.38);
    }

    .circles {
        width: 136px;
        height: 126px;
    }

    .gift-section {
        grid-template-columns: 1fr;
    }

    .gift-text-img {
        padding: 10px;
        flex-direction: column;
    }

    .gift-text h2 {
        color: #264E6B;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .gift-text p {
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 146.9%;
        margin-bottom: 0px;
    }

    .gift-btn {
        padding: 17px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin-top: 10px;
    }

    .gift-btn.mobile {
        display: block;
    }

    .gift-btn.desktop {
        display: none;
    }

    .gift-text-img {
        padding-bottom: 50px;
        padding-top: 24px;
    }

    .gift-img {
        gap: 20px;
    }

    .gift-img span {
        color: #4A4A4A;
        font-size: 20px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
    }
}
</style>