import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import NotFound from '@/views/404View.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Components from '@/views/ComponentsView.vue'
import Catalog from '@/views/СatalogView.vue'
import LK from '@/views/LK.vue'
import Gift from '@/views/GiftView.vue'
import Contacts from '@/views/ContactsView.vue'
import Settings from '@/views/LkSettings.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/ys/components',
        name: 'components',
        component: Components
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: Catalog
    },
    {
        path: '/lk',
        name: 'lk',
        component: LK
    },
    {
        path: '/gift/:id',
        name: 'gift',
        component: Gift
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

export default router
