<template>
    <div v-if="$store.state.typePopup != ''" class="popups" :class="$store.state.openPopup ? 'open' : ''">
        <div class="bg-popups" @click="closePopup"></div>
        <div ref="error_message" class="error-message" :class="error_message != '' ? 'active' : ''" v-html="error_message">
        </div>
        <form ref="form" @submit="formSubmit">
            <div v-if="this.$store.state.typePopup != 'select-district'" ref="close-btn" class="close-popup">
                <svg @click="closePopup" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M20.3125 5.6875L5.6875 20.3125" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.3125 20.3125L5.6875 5.6875" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-else-if="this.$store.state.currentDistrict != null" ref="close-btn" class="close-popup">
                <svg @click="closePopup" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M20.3125 5.6875L5.6875 20.3125" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.3125 20.3125L5.6875 5.6875" stroke="#C5C5C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="popup-avtoriz" v-if="$store.state.typePopup == 'avtoriz'">
                <div class="title">Войдите в систему.</div>
                <div class="avtoriz-registr">Нет аккаунта?<span @click="openRegistrate">Зарегистрироваться</span></div>
                <div class="input-title required-input">Почта
                    <input type="" name="username">
                </div>
                <div class="input-title password-input required-input">Пароль
                    <input type="password" name="password" placeholder="Введите пароль" id="pwd">
                    <img @click="passwordHidden('pwd')" :class="password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                    <img @click="passwordHidden('pwd')" :class="password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                </div>
                <div class="recover-password" @click="openResetPas">Забыли пароль?</div>
                <button>Войти</button>
            </div>  
            <div class="popup-registr" v-if="$store.state.typePopup == 'registr'">
                <div class="title">Регистрация.</div>
                <div class="registr-inputs">
                    <div class="input-title required-input">Фамилия дарителя *
                        <input name = "last_name"  type="">
                    </div>
                    <div class="input-title required-input">Укажите вашу почту *
                        <input name="email" type="">
                    </div>
                    <div class="input-title required-input">Имя дарителя *
                        <input name = "name" type="">
                    </div>
                    <div class="input-title">Организация
                        <input @change="changeCompany" v-model="company" name="company" type="">
                    </div>
                    <div class="input-title required-input">Номер телефона *
                        <input name="phone" type="" v-model="phone">
                    </div>
                    <div class="input-title">Логотип организации
                        <div class="input"> {{ file_name }}
                            <label id="folder-input" for="folder"><img src="@/assets/img/folder-add.png" alt=""></label>
                            <input @change="fileAdd" id="folder" ref="file" name="" type="file" accept="image/png, image/jpg, image/jpeg">
                            <img class="logo-company-folder" ref="logotip" src="" alt="">
                        </div>
                    </div>
                    <div class="is-company" :class="is_company_bloced == true ? 'bloced' : ''">
                        <input id="is_company" v-model="is_company" type="checkbox">
                        <label for="is_company">Указывать организацию как дарителя</label>                    
                    </div>
                    <p>Нажимая кнопку Вы соглашаетесь,<br><a href="https://теплыйдень.рф/privacy-policy/">с политикой конфиденциальности</a></p>
                    <button>Зарегистрироваться</button>
                </div>
            </div>
            <div class="popup-recover" v-if="$store.state.typePopup == 'recover'">
                <div class="title">Восстановление пароля.</div>
                <p>Для восстановления пароля введите почту, к которой привязан аккаунт. Мы отправим на нее новый пароль.</p>
                <div class="input-title">Почта
                    <input name="email" type="">
                </div>
                <button>Отправить</button>
            </div>
            <div class="popup-anonymity" v-if="$store.state.typePopup == 'anonymity'">
                <div class="title">Конфиденциальность.</div>
                <p>При бронировании подарка выберите, будет ли иформация о дарителе отображаться.</p>
                <div class="inputs-anonymity">
                    <input type="radio" id="anonymity-chekbox1" checked name="anonymous"/>
                    <label for="anonymity-chekbox1">Указать данные дарителя</label>
                    <input class="anonymous" type="radio" id="anonymity-chekbox2" name="anonymous"/>
                    <label for="anonymity-chekbox2">Опубликовать анонимно</label>
                </div>
                <button>Забронировать</button>
            </div>
            <div class="success-registr" v-if="$store.state.typePopup == 'success-registr'">
                <div class="success-registr-text">
                    <div class="title">Вы успешно зарегистрировались.</div>
                    <p>На почту test@mail.ru мы отправили Вам данные для входа. Изменить пароль вы можете в настройках личного кабинета.</p>
                    <hr>
                    <p>При бронировании подарка выберите, будет ли иформация о дарителе отображаться.</p>
                </div>
                <div class="inputs-anonymity">
                    <input type="radio" id="anonymity-chekbox3" name="contact"/>
                    <label for="anonymity-chekbox3">Указать данные дарителя</label>
                    <input type="radio" id="anonymity-chekbox4" name="contact"/>
                    <label for="anonymity-chekbox4">Опубликовать анонимно</label>
                </div>
                <button>Забронировать</button>
            </div>
            <div class="success-booked" v-if="$store.state.typePopup == 'success-booked'">
                <div class="title">Вы успешно<br> забронировали подарок.</div>
                <button @click="closePopup">окей</button>
            </div>
            <div class="new-password" v-if="$store.state.typePopup == 'new-password'">
                <div class="title">Восстановление пароля.</div>
                <p>Придумайте и введите новый пароль для Вашего аккаунта.</p>
                <div class="input-title password-input">Пароль
                    <input type="password" placeholder="Введите пароль" name="password" id="setpassword">
                    <img @click="passwordHidden('setpassword')" :class="password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                    <img @click="passwordHidden('setpassword')" :class="password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                </div>
                <div class="input-title password-input">Подтвержение пароля
                    <input type="password" placeholder="Введите пароль" name="password" id="confirmpassword">
                    <img @click="passwordHidden('confirmpassword')" :class="confirm_password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                    <img @click="passwordHidden('confirmpassword')" :class="confirm_password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                </div>
                <button>Войти</button>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-avtoriz'">
                <div class="title">Вы успешно авторизовались.</div>
                <p>При бронировании подарка выберите, будет ли иформация о дарителе отображаться.</p>
                <div class="inputs-success-avtoriz">
                    <input type="radio" id="anonymity-chekbox1" name="anonymous"/>
                    <label for="anonymity-chekbox1">Указать данные дарителя</label>
                    <input class="anonymous" type="radio" id="anonymity-chekbox2" name="anonymous"/>
                    <label for="anonymity-chekbox2">Опубликовать анонимно</label>
                </div>
                <button>Забронировать</button>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-avtorization'">
                <div class="title">Вы успешно авторизовались.</div>
                <div @click="closePopup" class="accept">Окей</div>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-registration'">
                <div class="title">Вы успешно зарегистрировались.</div>
                <p>Вам на почту был отправлен пароль.</p>
                <div @click="closePopup" class="accept">Окей</div>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-recover'">
                <div class="title">Вы успешно изменили пароль.</div>
                <p>Вам на почту был отправлен новый пароль.</p>
                <div @click="closePopup" class="accept">Окей</div>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-password'">
                <div class="title">Вы успешно изменили пароль.</div>
                <p>Ваш пароль был изменён.</p>
                <div @click="closePopup" class="accept">Окей</div>
            </div>
            <div class="success-avtoriz" v-if="$store.state.typePopup == 'success-data'">
                <div class="title">Вы успешно изменили данные.</div>
                <p>Ваши данные были изменены.</p>
                <div @click="closePopup" class="accept">Окей</div>
            </div>
            <!-- <div class="select-district" v-if="$store.state.typePopup == 'select-district'">
                <div class="title">Выберите муниципальный район.</div>
                <div class="district-list">
                    <div class="district" @click="setCurentDistrict(item.id, index)" v-for="item, index in $store.state.site_info.map">
                        <div class="district-img">
                            <img :src="item.emblem" alt="">
                        </div>
                        <p>{{ item.name }}</p>
                    </div>
                </div>
            </div> -->
        </form>
    </div>    
</template>
    
<script>
import { validateEmail, replaceNumberForPaste } from "@/assets/js/helper";

import popupsApi from "@/assets/js/api/popups.js";

export default {
    name: 'commonPopup',
    components: {

    },
    data() {
        return {
            password_hidden: true,
            confirm_password_hidden: true,
            timerCount: 99,
            districts: [],
            data: [],
            filters: {},
            pagination: {},
            currentDistrict: {},
            error_message: '',
            file_name: 'Выберите файл',
            is_avtor: false,
            logo_company: null,
            is_company: false,
            is_company_bloced: false,
            company: '',
            phone: '',
        }
    },
    methods: {
        closePopup () {
            if (this.$store.state.typePopup != 'select-district') {
                this.$store.commit('openPopup', false);
                this.error_message = '';
            } else if (this.$store.state.currentDistrict != null) {
                this.$store.commit('openPopup', false);
                this.error_message = '';
            }
        },
        passwordHidden(input) {
            if (input == 'confirmpassword') {
                this.confirm_password_hidden = !this.confirm_password_hidden
                if (this.confirm_password_hidden == false) {
                    this.show(input);
                } else {
                    this.hide(input);
                }
            } else {
                this.password_hidden = !this.password_hidden
                if (this.password_hidden == false) {
                    this.show(input);
                } else {
                    this.hide(input);
                }
            }
        },

        // setCurentDistrict(id, index) {
        //     let result = JSON.parse(sessionStorage.getItem('site_info'));
        //     this.$store.commit('setCurrentDistrict', result.map[index]);
        //     localStorage.setItem('setCurrentDistrict', JSON.stringify(result.map[index]));
        //     sessionStorage.setItem('site_info', JSON.stringify(result));
        //     this.$store.commit('site_info', result)
        //     this.$store.commit('openPopup', false)
            
        // },

        fileAdd() {
            const form = this.$refs.form;
            const input = form.querySelector('#folder');
            const logo = this.$refs.logotip;
            if (input) {
                let fileInput = input.files[0];
                this.logo_company = fileInput
                var reader  = new FileReader();
                reader.onload = function () {
                    if (reader.result) {
                        logo.src = reader.result  
                    }
                };
                reader.readAsDataURL(input.files[0]);
                this.file_name = input.value.split('#')[0].split('?')[0].split('\\').pop()
            }
        },

        openResetPas() {
            this.$store.commit('openPopup', false)
            setTimeout(() => {
                this.$store.commit('setTypePopup', 'recover')
                this.$store.commit('openPopup', true);
            }, 800);
        },

        requireInput(e) {
            const form = this.$refs.form;
            if (form) {
                const inputs = form.querySelectorAll('.required-input');
                inputs.forEach(item => {
                    const input = item.querySelector('input');
                    input.addEventListener('input', ()=> {
                        item.classList.remove('required');
                        this.error_message = "";
                        input.style.borderColor = ''
                    })
                    if (input.value == '') {
                        input.style.borderColor = '#A01717'
                        item.classList.add('required');
                        return false;
                    }
                });

                const email = form.querySelector('input[name="email"]');
                if (email) {
                    if (!validateEmail(email.value)) {
                        email.parentNode.style.borderColor = '#A01717';
                        email.classList.add('required');
                        this.error_message = "Неверный формат почты.";

                        return false;
                    } else {
                        email.parentNode.style.borderColor = '';
                        email.classList.remove('required');
                        this.error_message = "";
                    }
                }
                
                const phone = form.querySelector('input[name="phone"]');
                if (phone) {
                    if (phone.value.length < 18) {
                        phone.parentNode.style.borderColor = '#A01717';
                        phone.classList.add('required');
                        this.error_message = "Неверный формат телефона.";

                        return false;
                    } else {
                        phone.parentNode.style.borderColor = '';
                        phone.classList.remove('required');
                        this.error_message = "";
                    }
                }
            }
            return true;
        },

        openRegistrate() {
            this.$store.commit('openPopup', false)
            setTimeout(() => {
                this.$store.commit('setTypePopup', 'registr')
                this.$store.commit('openPopup', true);
            }, 800);
        },
        async formSubmit(e) {
            e.preventDefault();
            const form = this.$refs.form;
            const inputs = form.querySelectorAll('input');
            const valid = this.requireInput();
                const data = {}

                inputs.forEach(element => {
                    data[element.name] = element.value;
                })

            if (valid) {
                if (this.$store.state.typePopup == 'avtoriz') {
                    const result = await popupsApi.get(data);
                    if (result?.message) {
                        this.error_message = result.message
                        if (result.message) {
                            setTimeout(() => {
                                const link = this.$refs.error_message.querySelector('a');
                                if (link) {
                                    link.removeAttribute("href")
                                    link.addEventListener('click', () => {
                                        this.error_message = "";
                                        this.$store.commit('openPopup', false)
                                        setTimeout(() => {
                                            this.$store.commit('setTypePopup', 'registr')
                                            this.$store.commit('openPopup', true);
                                        }, 800);
                                    })
                                }
                            }, 100);
                        }
                    }

                    if (result?.token) {
                        localStorage.setItem('token', JSON.stringify(result.token));
                        this.$store.commit('setToken', result.token);
                        this.$store.commit('openPopup', false)

                        if (this.$store.state.is_gift == true) {
                            setTimeout(() => {
                                this.$store.commit('setTypePopup', 'success-avtoriz')
                                this.$store.commit('openPopup', true);
                            }, 800);
                        } else {
                            setTimeout(() => {
                                this.$store.commit('setTypePopup', 'success-avtorization')
                                this.$store.commit('openPopup', true);
                            }, 800);
                        }
                    }
                }
                if (this.$store.state.typePopup == 'registr') {
                    const formdata = new FormData();
                    formdata.append("last_name", data.last_name);
                    formdata.append("name", data.name);
                    formdata.append("phone", data.phone);
                    formdata.append("email", data.email);
                    formdata.append("company", data.company);
                    formdata.append("file", this.$refs.file.files[0]);
                    formdata.append("is_company", data.is_company);
                    let result = await popupsApi.reg(formdata);
                    if (result?.message) {
                        this.error_message = result.message
                    }

                    if (result.success == true) {
                        this.$store.commit('openPopup', false)
                        this.error_message = '';
                        setTimeout(() => {
                            this.$store.commit('setTypePopup', 'success-registration')
                            this.$store.commit('openPopup', true);
                        }, 800);
                    }

                    if (result?.success == true) {
                    this.error_message = '';
                }

                }
                if (this.$store.state.typePopup == 'recover') {
                    const result = await popupsApi.rec(data);
                    if (result?.message) {
                        this.error_message = result.message
                    }

                    if (result.success == true) {
                        this.error_message = '';
                        this.$store.commit('openPopup', false)
                        setTimeout(() => {
                            this.$store.commit('setTypePopup', 'success-recover')
                            this.$store.commit('openPopup', true);
                        }, 800);
                    }
                }
                if (this.$store.state.typePopup == 'anonymity' || this.$store.state.typePopup == 'success-avtoriz') {
                    
                    data.gift_id = this.$store.state.giftId;
                    if (form.querySelector('.anonymous').checked == true) {
                        data.anonymous = 'on'
                    } else {
                        data.anonymous = ''
                    }
                    
                    const result = await popupsApi.anon(data);
                    console.log(result);
                    if (result?.message) {
                        this.error_message = result.message
                    }
                    if (result.success == true) {
                        this.error_message = '';
                        this.$store.commit('openPopup', false)
                        setTimeout(() => {
                            this.$store.commit('setTypePopup', 'success-booked')
                            this.$store.commit('openPopup', true);
                        }, 800);
                    }
                }
            }
        },
        show(input) {
            var p = document.getElementById(input);
            p.setAttribute('type', 'text');
        },

        changeCompany() {
            if (this.company != '') {
                this.is_company_bloced = true
            } else {
                this.is_company_bloced = false
            }
        },

        hide(input) {
            var p = document.getElementById(input);
            p.setAttribute('type', 'password');
        },
        setDistrict(new_value) {
            const site_info = new_value;
            this.districts = site_info.map
        },
    },
    computed: {

    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            immediate: true
        },
        phone(new_value) {
            this.phone = replaceNumberForPaste(new_value);
        }
    },
    mounted() {
        this.is_company_bloced = false
    }
}
</script>
<style scoped>

    * {
        margin: 0px;
    }

    .popups {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        opacity: 0;
        pointer-events: none;
        transition: 0.8s;
    }

    .popups.open {
        opacity: 1;
        pointer-events: all;
    }

    .bg-popups {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.40);
    }

    .popups form {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 20px;
        padding: 40px;
        background: #FFF;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.10);
        height: auto;
        max-height: 80lvh;
        overflow: auto;
    }

    .close-popup {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .close-popup svg {
        cursor: pointer;
    }

    input,
    .input {
        border-radius: 20px;
        border: 1px solid #4A4A4A;
        background: #FFF;
        width: 591px;
        padding: 13px;
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 15px;
        transform: translateX(-15px);
    }

    .input {
        padding-left: 90px;
        position: relative;
    }
    
    #folder {
        opacity: 0;
        position: absolute;
        z-index: -1;
        width: 0px;
        height: 0px;
    }

    #folder-input {
        position: absolute;
        top: 11px;
        left: 40px;
        z-index: 10;
        cursor: pointer;
    }

    #folder-input img {
        
    }

    .popup-avtoriz .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px;
        margin-bottom: 10px;
    }
    
    .input-title {
        display: flex;
        flex-direction: column;
        gap: 3px;
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-left: 15px;
    }

    .required-input {
        position: relative;
    }

    .required-input::after {
        content: "Обязательное поле";
        padding: 6px 12px;
        background-color: #FFF;
        color: #A01717;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: calc(100% - 28px);
        left: 50%;
        transform: translateX(-50%);
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.20));
        opacity: 0;
        transition: 0.5s;
    }

    .required-input.required::after {
        opacity: 1;
        pointer-events: all;
        transform: translateX(-50%) translateY(14px);
    }


    .avtoriz-registr {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        color: #4A4A4A;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .avtoriz-registr span {
        color: #264E6B;
        font-size: 22px;
        font-style: normal;
        font-weight: 550;
        line-height: 146.9%;
        text-decoration-line: underline;
    }

    .recover-password {
        color: #264E6B;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: right;
    }

    .popups button {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 550;
        line-height: 153%;
        text-transform: uppercase;
        border-radius: 20px;
        background: #145C8E;
        border: 2px solid #145C8E;
        width: 100%;
        text-align: center;
        padding: 13px;
        transition: 0.8s;
    }

    .popups button:hover {
        background: #FFF;
        color: #145C8E;
    }

    .popup-avtoriz button {
        margin-top: 50px;
    }

    .popup-registr button {
        margin-top: 42px;
    }

    .registr-inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 45px;
        row-gap: 0px;
    }

    .registr-inputs p {
        color: #4A4A4A;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0px;
        margin-top: auto;
    }

    .registr-inputs a {
        color: #4A4A4A;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .popup-registr .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px;
    }

    .popup-recover {
        max-width: 576px;
    }

    .popup-recover .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .popup-recover p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin-bottom: 40px;
    }

    .popup-recover button {
        margin-top: 35px;
    }

    .popup-anonymity {
        max-width: 576px;
    }

    .popup-anonymity .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        margin-bottom: 10px;
    }

    .popup-anonymity p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin: 0px;
        margin-bottom: 20px;
    }

    .inputs-anonymity {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 40px;
    }

    .popup-anonymity label {
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .popup-anonymity input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 0px;
        height: 0px;
    }

    .popup-anonymity input+label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .popup-anonymity input+label::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 25px;
        border: 2px solid #424242;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }

    .popup-anonymity input:checked+label::before {
        background-image: url("@/assets/img/cheked.svg");
    }

    .password-input {
        position: relative;
    }

    .password-input img {
        position: absolute;
        bottom: 26px;
        right: 40px;
        transition: 0.4s;
        width: 24px;
        height: 24px;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
    }

    .password-input img.active {
        opacity: 1;
        pointer-events: all;
    }

    .success-registr {
        max-width: 576px;
    }

    .success-registr-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .success-registr-text hr {
        background: #424242;
        height: 1px;
        width: 100%;
    }

    .success-registr p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
    }

    .success-registr .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


    .success-registr label {
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .success-registr input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .success-registr input+label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .success-registr input+label::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 25px;
        border: 2px solid #424242;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }

    .success-registr input:checked+label::before {
        background-image: url("@/assets/img/cheked.svg");
    }

    .email-confirmation {
        display: flex;
        flex-direction: column;
        max-width: 576px;
    }

    .email-confirmation .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .email-confirmation p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin-bottom: 40px;
    }

    .email-confirmation input {
        margin-bottom: 50px;
    }

    .email-confirmation-timer {
        margin-top: 15px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 15px;
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
    }

    .email-confirmation-timer span {
        color: #145C8E;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
    }

    .success-booked {
        display: flex;
        flex-direction: column;
        width: 576px;
        padding-bottom: 55px;
    }

    .success-booked .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .success-booked p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin-bottom: 40px;
    }

    .new-password {
        max-width: 576px;
    }

    .new-password {
        padding-bottom: 40px;
    }

    .new-password .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .new-password p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%;
        margin-bottom: 40px;
    }

    .new-password button {
        margin-top: 50px;
    }

    .new-password .password-input {
        margin-bottom: 35px;
    }

    .new-password .password-input:last-of-type {
        margin-bottom: 0px;
    }

    .success-avtoriz {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .inputs-success-avtoriz {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .success-avtoriz .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .success-avtoriz p {
        color: #4A4A4A;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.9%; /* 38.194px */
    }

    .success-avtoriz label {
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .success-avtoriz input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .success-avtoriz input+label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .success-avtoriz input+label::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 25px;
        border: 2px solid #424242;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }

    .success-avtoriz input:checked+label::before {
        background-image: url("@/assets/img/cheked.svg");
    }

    .select-district .title {
        color: #4A4A4A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px;
        margin-top: 32px;
    }

    .select-district {
        width: 576px;
    }

    .district-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 15px;
    }

    .district {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
    }

    .district p {
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
    }

    .district-img {
        width: 26px;
        height: 26px;
    }

    .district-img img {
        height: 100%;
        width: auto;
    }

    .error-message {
        position: absolute;
        width: max-content;
        max-width: 565px;
        border: 2px solid #A01717;
        border-radius: 20px;
        color: #A01717;
        font-size: 20px!important;
        padding: 14px 18px;
        left: 50%;
        top: 0px;
        transform: translateX(-50%) translateY(-100%);
        opacity: 0;
        transition: 0.8s;
        pointer-events: none;
        background: #FFF;
        z-index: 10;
    }

    .error-message strong {
        font-size: 20px;
        color: #145C8E;
    }

    .error-message a {
        font-size: 20px;
        text-decoration: underline;
        font-weight: 600;
    }

    .error-message.active {
        transform: translateX(-50%) translateY(30px);
        opacity: 1;
        pointer-events: all;
    }

    .logo-company-folder {
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
    }

    .accept {
        cursor: pointer;
        border-radius: 20px;
        background: #145C8E;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 550;
        line-height: 153%;
        text-transform: uppercase;
        padding: 13px;
        width: 100%;
        max-width: 576px;
        margin: 0px auto;
        text-align: center;
    }

    @media screen and (max-width: 1200px) {

        .accept {
            font-size: 18px;
            padding: 8px;
        }

        .close-popup svg {
            width: 18px;
            height: 18px;
        }

        .popups form {
            padding: 20px;
            /* margin: 0px 20px; */
            max-width: 320px;
            width: 100%;
            padding-bottom: 50px;
        }

        .popup-avtoriz .title,
        .popup-registr .title,
        .popup-recover .title,
        .popup-anonymity .title,
        .success-registr .title,
        .email-confirmation .title,
        .success-booked .title,
        .new-password .title,
        .success-avtoriz .title {
            color: #4A4A4A;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 32px;
        }

        .input-title {
            color: #4A4A4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        .popups button {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
            text-transform: uppercase;
            padding: 17px;
            margin-top: 50px;
        }


        input {
            width: calc(100% + 15px);
            font-size: 14px;
        }

        .avtoriz-registr {
            flex-direction: row;
            gap: 10px;
            align-items: flex-start;
            color: #4A4A4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .avtoriz-registr span {
            color: #264E6B;
            font-size: 16px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
            text-decoration-line: underline;
        }

        .recover-password {
            color: #264E6B;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .password-input img {
            bottom: 25px;
            right: 15px;
        }

        .registr-inputs {
            grid-template-columns: 1fr;
            row-gap: 0px;
        }

        .popup-registr .title  {
            margin-bottom: 20px;
            color: #4A4A4A;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .registr-inputs p {
            color: #4A4A4A;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 20px;
        }

        .registr-inputs a {
            color: #4A4A4A;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .popup-anonymity .title {
            margin-bottom: 10px;
        }

        .popup-anonymity p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .popup-anonymity label {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .success-registr p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .success-registr label {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .success-registr input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            width: 0px;
            height: 0px;
        }

        .success-registr input+label::before {
            width: 24px;
            height: 24px;
        }

        .success-registr .inputs-anonymity {
            margin-top: 30px;
        }

        .success-registr-text hr {
            height: 1px;
        }

        .email-confirmation input {
            margin-bottom: 0px;
            transform: none;
            width: 100%;
        }

        .email-confirmation p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .email-confirmation-timer {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .email-confirmation-timer span {
            color: #145C8E;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .success-booked {
            width: 100%;
            padding-bottom: 0px;
        }

        .success-booked p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .new-password {
            padding-bottom: 0px;
        }

        .new-password p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .new-password .password-input {
            margin-bottom: 0px;
        }

        .password-input:last-of-type input {
            margin-bottom: 0px;
        }

        .password-input:last-of-type img {
            bottom: 11px;
        }

        .success-avtoriz p {
            color: #4A4A4A;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.9%;
        }

        .success-avtoriz label {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .success-avtoriz input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            width: 0px;
            height: 0px;
        }

        .success-avtoriz input+label::before {
            width: 24px;
            height: 24px;
        }

        .success-avtoriz button {
            margin-top: 0px;
        }

        .select-district {
            width: 100%;
        }

        .district-list {
            grid-template-columns: 1fr;
        }

        .district p {
            color: #4A4A4A;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        .district-img {
            width: 20px;
            height: 20px;
        }

        .district-img img  {
            height: 100%;
        }

        .select-district .title {
            color: #4A4A4A;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }   

</style>