<template>
    <footer>
        <div class="footer-top">
            <div class="footer-col">
                <a href="/" class="footer-logo">
                    <img :src="require('@/assets/img/logo-1.svg')" alt="">
                    |
                    <img :src="require('@/assets/img/logo.png')" alt="">
                    |
                    <img :src="require('@/assets/img/logo_ymolod.png')" alt="">
                </a>
                <a href="/" class="footer-logo yamal-logo">
                    <img :src="require('@/assets/img/yamal-logo.png')" alt="">
                    <p>при поддержке <span>АК «Ямал»</span></p>
                </a>
            </div>
            <div class="footer-col">
                <a href="/">каталог</a>
                <a v-if="$route.path == '/'" @click="moveTo('#about')">Об акции</a>
                <a v-else href="/?section=about">Об акции</a>
                <a v-if="$route.path == '/'" @click="moveTo('#curators')">Координаторы</a>
                <a v-else href="/?section=curators">Координаторы</a>
            </div>
            <div class="footer-col">
                <a href="/contacts">Контакты</a>
                <a v-if="$store.state.token != null " href="/lk">Личный кабинет</a>
                <a v-else @click="openAvtoriz">Личный кабинет</a>
            </div>
            <div class="footer-col right">
                <a :href="'tel:' + $store.state.site_info?.phone">{{ $store.state.site_info?.phone }}</a>
                <a :href="'mailto:' + $store.state.site_info?.email">{{ $store.state.site_info?.email }}</a>
                <div class="socials">
                    <span>
                        Мы в соц. сетях:
                    </span>
                    <a :href="item.link" target="_blank" v-for="item in $store.state.site_info?.media">
                        <img :src="item.img" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-info">
                <span>©  2023</span>
                <a href="/privacy-policy">Политика конфиденциальности</a>
                <a :href="$store.state.site_info?.regulation" target="_blank">Положение</a>
                <a :href="$store.state.site_info?.questions" target="_blank">Есть вопросы или предложения?</a>
            </div>
            <div class="footer-dev">
                <span>Разработка сайта</span>
                <a href="https://your-startup.space/" target="_blank">
                    <img :src="require('@/assets/img/ys_logo.svg')" alt="">
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    props: {
        moveTo: null,
    },
    data() {
        return {
            regulation: JSON.parse(sessionStorage.getItem('site_info'))?.regulation
        }
    },
}
</script>

<style>
footer {
    background-color: var(--light-gray);
    color: var(--black);
    padding: 40px;
}

.footer-top {
    display: flex;
    column-gap: 40px;
}

.footer-col {
    width: 20%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.footer-col:first-of-type {
    width: 40%;
}

.footer-col.right {
    align-items: flex-end;
}

.footer-top a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    color: var(--black);
    cursor: pointer;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-logo img {
    height: 60px;
}

.footer-bottom {
    margin-top: 40px;
    padding: 40px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-info {
    display: flex;
    align-items: center;
    column-gap: 13px;
}

.footer-info ::after {
    content: "|";
    margin-left: 13px;
}

.footer-info a:last-of-type::after {
    display: none;
}

.footer-info, .footer-info * {
    color: var(--black);
    font-size: 20px;
    text-decoration: none;
}

.footer-dev span {
    font-size: 14px;
    color: var(--black);
    margin-right: 10px;
}

.socials {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.socials a {
    height: 32px;
}

.socials span {
    font-size: 20px;
    color: var(--black);
}

.yamal-logo {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.yamal-logo span {
    display: block;
    white-space: normal;
}

@media screen and (max-width: 1600px) {
    .footer-logo img {
        height: 45px;
    }
}

@media screen and (max-width: 1200px) {

    footer {
        padding: 20px;
        padding-bottom: 50px;
    }

    .footer-top,
    .footer-bottom {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }

    .footer-logo img {
        height: 25px;
    }

    .footer-info {
        flex-direction: column;
    }

    .footer-col {
        width: 100%;
    }

    .footer-col.right {
        align-items: flex-start;
    }

    .footer-info {
        gap: 25px;
        align-items: flex-start;
    }

    .footer-info ::after {
        display: none;
    }

    .yamal-logo span,
    .yamal-logo p {
        font-size: 14px;
    }

    .footer-col:first-of-type { 
        width: 100%;
    }
}
</style>