<template>
    <Preloader />
    <router-view />
</template>

<script>
import Preloader from '@/components/base/Preloader.vue';

export default {
    name: 'App',
    components: {
        Preloader,
    },
    async mounted() {
    }
}
</script>

<style>
@font-face {
    font-family: "HelveticaNeueCyr";
    src: local("HelveticaNeueCyr"),
        url("./assets/fonts/HelveticaNeueCyr/helveticaneuecyr_roman.otf");
    font-display: swap;
}

@font-face {
    font-family: 'Mail Sans Roman DemBd';
    src: url('./assets/fonts/MailSansRoman/MailSansRoman-DemiBold.eot');
    src: local('Mail Sans Roman DemiBold'), local('MailSansRoman-DemiBold'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-DemiBold.woff2') format('woff2'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-DemiBold.woff') format('woff'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mail Sans Roman Med';
    src: url('./assets/fonts/MailSansRoman/MailSansRoman-Medium.eot');
    src: local('Mail Sans Roman Medium'), local('MailSansRoman-Medium'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Medium.woff2') format('woff2'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Medium.woff') format('woff'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman/MailSansRoman-Bold.eot');
    src: local('Mail Sans Roman Bold'), local('MailSansRoman-Bold'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Bold.woff2') format('woff2'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Bold.woff') format('woff'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman/MailSansRoman-Regular.eot');
    src: local('Mail Sans Roman Regular'), local('MailSansRoman-Regular'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Regular.woff2') format('woff2'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Regular.woff') format('woff'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman/MailSansRoman-Light.eot');
    src: local('Mail Sans Roman Light'), local('MailSansRoman-Light'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Light.woff2') format('woff2'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Light.woff') format('woff'),
        url('./assets/fonts/MailSansRoman/MailSansRoman-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

:root {
    --black:      #0B0715;
    --red:        #C10E3F;
    --blue:       #69A0C7;
    --light-gray: #F3F3F3;
}

* {
    box-sizing: border-box;
    font-family: "Mail Sans Roman";
}

body {    
    font-size: 2.7vh;
    margin: 0;
    overflow-x: hidden;
    color: var(--black);
}

.scroll-content {
    will-change: transform;
}

section {
    padding-bottom: 100px;
}

.container {
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;
}

.container.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scene {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

h1 {
    color: #07395C;
    font-family: HelveticaNeueCyr;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    gap: 1vh;
    z-index: 10;
}

h1 span {
    font-size: 4vh;
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2vh #fff;
    width: max-content;
}

h1 span::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: #b4b4b4;
    right: 1vh;
    z-index: -1;
}

h2 {
    color: #07395C;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 1vh;
    z-index: 10;
}

h2 span {
    font-size: 4vh;
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2vh #fff;
    width: max-content;
}

h2 span::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: #b4b4b4;
    right: 1vh;
    z-index: -1;
}

a.btn {
    padding: 32px 140px 32px 140px; 
    border-radius: 24px; 
    border: 1px solid var(--red);
    background-color: var(--red);
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; 
    transition: 0.5s;
}

a.btn:hover {
    color: var(--red);
    background-color: #FFF;
}

a.btn.center {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1200px) {
    a.btn {
        padding: 20px; 
        width: 100%;
        font-size: 16px;
    }
}
</style>