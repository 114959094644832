<template>
    <section id="form">
        <div class="container">
            <div class="form-container">
                <h2 class="form-title">
                    Остались вопросы?
                </h2>
                <div class="form">
                    <div class="input-wrapper">
                        <img src="@/assets/img/phone.svg" alt="" class="input-icon">
                        <input type="text" placeholder="Телефон" v-model="phone" :disabled="is_success">
                        <div :class="error_phone == '' ? 'form-success' : 'form-error'">
                            {{ error_phone }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <img src="@/assets/img/email.svg" alt="" class="input-icon">
                        <input type="text" placeholder="Email" v-model="email" :disabled="is_success">
                        <div :class="error_email == '' ? 'form-success' : 'form-error'">
                            {{ error_email }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <img src="@/assets/img/user.svg" alt="" class="input-icon">
                        <input type="text" placeholder="Имя" v-model="name" :disabled="is_success">
                        <div :class="error_name == '' ? 'form-success' : 'form-error'">
                            {{ error_name }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <button v-if="!is_success" @click="createOrder">Отправить</button>
                        <button v-else disabled="disabled">Заявка отправлена</button>
                    </div>
                </div>
                <div class="form-text">
                    <div class="form-error-order" v-if="error_order" v-html="error_order"></div>
                    Нажимая кнопку Вы соглашаетесь, <a href="/privacy-policy">с политикой конфиденциальности</a>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { replaceNumberForPaste, validateEmail } from '@/assets/js/helper.js';
import orderApi from '@/assets/js/api/order.js'

export default {
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            error_name: '',
            error_phone: '',
            error_email: '',
            is_validated: false,
            error_order: '',
            is_success: false,
        }
    },
    methods: {
        async createOrder() {
            this.error_order = '';

            if (this.validate()) {
                const result = await orderApi.create(this.name, this.phone, this.email);

                if (result.success) {
                    this.name = '';
                    this.phone = '';
                    this.email = '';
                    this.error_name = '';
                    this.error_phone = '';
                    this.error_email = '';
                    this.is_validated = false;
                    this.is_success = true;
                } else {
                    this.error_name = '';
                    this.error_phone = '';
                    this.error_email = '';
                    this.is_validated = false;
                    this.error_order = result.message;
                }
            }
        },
        validate() {
            this.error_name = '';
            this.error_phone = '';
            this.error_email = '';

            if (this.name == '') {
                this.error_name = 'Обязательное поле.';
            }

            if (this.phone == '') {
                this.error_phone = 'Обязательное поле.';
            } else if (this.phone.length < 18) {
                this.error_phone = 'Номер должен быть в формате +7 (999) 999 99 99';
            }

            if (this.email == '') {
                this.error_email = 'Обязательное поле.';
            } else if (!validateEmail(this.email)) {
                this.error_email = 'Email должен быть в формате name@email.com';
            }

            this.is_validated = true;

            return this.error_name == '' && this.error_phone == '' && this.error_email == '';
        }
    },
    watch: {
        name(new_value) {
            this.name = new_value.substring(0, 50);
            if (this.is_validated) {
                this.validate();
            }
        },
        phone(new_value) {
            this.phone = replaceNumberForPaste(new_value);
            if (this.is_validated) {
                this.validate();
            }
        },
        email(new_value) {
            this.email = new_value.substring(0, 50);
            if (this.is_validated) {
                this.validate();
            }
        }
    }
}
</script>
<style>
.form-container {
    border-radius: 30px;
    padding: 40px 50px;
    background-color: var(--blue);
}

.form-title {
    color: #FFF;
    font-family: Mail Sans Roman;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 66px */
    text-align: center;
    width: 100%;
}

.form {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.input-wrapper {
    width: 100%;
    position: relative;
}

.input-icon {
    position: absolute;
    top: 22px;
    left: 14px;
}

.form input {
    border-radius: 10px;
    border: none;
    background: #FFF;
    margin-bottom: 10px;
    outline: none;
    padding: 20px 20px 20px 44px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.form input::placeholder {
    color: #B7B7B7;
}

.form button {
    border-radius: 10px;
    background: var(--red);
    text-align: center;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%;
    transition: 0.5s;
    border: 1px solid var(--red);
    width: 100%;
    padding: 22px;
}

.form button:disabled {
    pointer-events: none;
}

.form button:hover {
    background-color: #FFF;
    color: var(--red);
}

.form-text {
    color: rgba(255, 255, 255, 0.5);
    font-family: Mail Sans Roman;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.form-text a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    transition: 0.5s;
}

.form-text a:hover {
    color: #FFF;
}

.form-container-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-errors-container {
    display: flex;
    flex-direction: column;
}

.form-error,
.form-success {
    display: flex;
    min-height: 24px;
    font-size: 18px;
}

.form-error {
    color: var(--red);
}

.form-success {
    color: #37a017;
}

.form-error-order {
    font-size: 18px;
    color: var(--red);
    margin-bottom: 22px;
}

@media screen and (max-width: 1200px) {
    .form {
        flex-direction: column;
    }

    .form-container {
        padding: 20px;
    }

    .form-title {
        font-size: 24px; 
        margin-bottom: 30px;
    }

    .form-text {
        margin-top: 20px;
        font-size: 16px; 
    }

    .form-error, 
    .form-error-order {
        font-size: 16px; 
    }
}
</style>