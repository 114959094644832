<template>
    <section id="curators">
        <div class="container">
            <h2>Координаторы</h2>
            <div class="curators-text">
                Подробнее об акции вы можете узнать из положения, а если у вас есть к нам вопросы или предложения, свяжитесь с координатором из своего муниципалитета.
            </div>
            <a class="btn center" :href="regulation" download target="_blank">Положение</a>
            <div class="curators-wrapper" ref="wrapper">
                <div class="curators-container">
                    <div class="curator" v-for="(curator, index) in data" :key="index">
                        <div class="curator-city">{{ curator.city }}</div>
                        <img :src="curator.img" :alt="curator.second_name + ' ' + curator.name">
                        <div class="curator-text">
                            <div class="curator-name">{{ curator.name }}</div>
                            <div class="curator-second-name">{{ curator.second_name }}</div>
                        </div>
                        <button class="curator-btn" @click="open(index)">
                            Контакты
                        </button>
                    </div>
                </div>
            </div>
            <button class="more-curators" v-if="!is_show" @click="more">Просмотреть всех</button>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Curators',
    props: {
        data: null,
        regulation: null,
    },
    data() {
        return {
            is_show: false
        }
    },
    methods: {
        open(index) {
            this.$store.commit('setCuratorsPopup', {
                is_open: true,
                current: this.data[index]
            });
        },
        more() {
            const height = this.$refs.wrapper.scrollHeight;
            this.$refs.wrapper.style.maxHeight = height + 'px';
            this.is_show = true;
            setTimeout(() => {
                this.$refs.wrapper.style.maxHeight = 'none';
            }, 1000);
        }
    }
}
</script>
<style>
#curators h2 {
    color: var(--black);
    width: 100%;
    text-align: center;
    font-family: Mail Sans Roman;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 0 0 40px;
}

.curators-text {
    max-width: 510px;
    margin: 0 auto 40px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    text-align: center;
}

.curators-wrapper {
    margin-top: 100px;
}

.curators-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.curator {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.curator img {
    width: 100%;
    height: calc((1440px - 160px) / 4);
    object-fit: cover;
    border-radius: 50%;
}

.curator-text {
    position: relative;
}

.curator-name {
    position: absolute;
    transform: translate(-25px, -30px) rotate(-13deg);
    transform-origin: left;
    border-radius: 24px;
    background: #FBFBFB;
    padding: 16px 32px;
    bottom: 0;
}

.curator-second-name {
    border-radius: 24px;
    background: #FBFBFB;
    padding: 16px 32px;
    margin-top: -27px;
}

.curator-btn {
    border-radius: 32px;
    border: 1px solid var(--red);
    background: var(--red);
    padding: 16px 40px; 
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-top: 10px;
}

.curator-city {
    color: rgba(0, 0, 0, 0.50); 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    height: 14px;
    margin-bottom: 26px;
}

.more-curators {
    display: none;
}

@media screen and (max-width: 1440px) {
    .curator img {
        height: calc((100vw - 160px) / 4);
    }
}

@media screen and (max-width: 1200px) {
    #curators h2 {
        font-size: 24px; 
        margin-bottom: 40px;
    }

    .curators-text {
        text-align: left;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .curators-wrapper {
        margin-top: 40px;
        max-height: calc((100vw - 60px) + ((30px + 51px) * 2) + 20px);
        overflow: hidden;
        transition: 1s;
    }

    .curators-container {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        max-height: 2fr;
        overflow: hidden;
    }

    .curator img {
        height: calc((100vw - 60px) / 2);
    }

    .curator-btn {
        padding: 10px 40px;
        font-size: 12px;
    }

    .curator-name,
    .curator-second-name {
        font-size: 12px; 
        padding: 10px 20px;
    } 

    .curator-name {
        transform: translate(-15px, -20px) rotate(-13deg);
    }

    .curator-city {
        font-size: 10px; 
        margin-bottom: 16px;
    }

    .more-curators {
        display: block;
        padding: 20px; 
        width: 100%;
        max-width: 380px;
        border-radius: 12px;
        border: 1px solid var(--red);
        color: var(--red);
        margin: 40px auto 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%; 
        outline: none;
        background-color: #FFF;
    }
}
</style>