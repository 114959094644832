import { createStore } from 'vuex'
import { pageModule } from './pageModule'
import { appModule } from './appModule'

export default createStore({
    state: () => {
        return {
            orderPopupShow: false,
            vacancyPopupShow: false,
            vacancyDesc: '',
            vacancyName: '',
            is_scroll: false,
            mobileMenuOpen: false,
            mobileMenuClose: false,
            successFormOrder: false,
            answerOpen: false,
            budgetError: false,
            rateError: false,
            mapOpen: false,  
            examplePopup: false,          
            supportPopupShow: false,
            typePopup: 'success-avtorization',
            openPopup: false,
            currentDistrict: null, 
            site_info: {},
            token: localStorage.getItem('token'),
            giftId: null,
            is_gift: false,
            curators_popup: {
                is_open: false,
                current: null,
            }
        }
    },
    getters: {

    },
    mutations: {
        examplePopup(state, bool) {
            state.examplePopup = bool;
        },
        setScroll(state, bool) {
            state.is_scroll = bool;
        },
        openMenu(state, bool) {
            state.mobileMenuOpen = bool;
        },
        closeMenu(state, bool) {
            state.mobileMenuClose = bool;
        },
        successOrder(state, bool) {
            state.successFormOrder = bool;
        },
        answerOpen(state, bool) {
            state.answerOpen = bool;
        },
        budgetError(state, bool) {
            state.budgetError = bool;
        },
        rateError(state, bool) {
            state.rateError = bool;
        },
        mapOpen(state, bool) {
            state.mapOpen = bool;
        },
        openVacancy(state, bool) {
            state.vacancyPopupShow = bool;
        },
        setVacancyDesc(state, string) {
            state.vacancyDesc = string;
        },
        setVacancyName(state, string) {
            state.vacancyName = string;
        },
        openPopup(state, bool) {
            state.openPopup = bool;
        },
        setTypePopup(state, string) {
            state.typePopup = string;
        },
        openSupport(state, bool) {
            state.supportPopupShow = bool;
        },
        site_info(state, object) {
            state.site_info = object;
        },
        setCurrentDistrict(state, object) {
            state.currentDistrict = object;
        },
        setToken(state, string) {
            state.token = string;
        },
        setGiftId(state, string) {
            state.giftId = string;
        },
        setIsGift(state, bool) {
            state.is_gift = bool;
        },
        setCuratorsPopup(state, object) {
            state.curators_popup = object;
        }
    },
    actions: {

    },
    modules: {
        page: pageModule,
        app: appModule
    }
})
