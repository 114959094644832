import Api from "@/assets/js/api/index.js";

class OrderApi extends Api {
    async create(name, phone, email) {
        try {
            const data = {
                name,
                phone,
                email
            }

            const result = await this.send('methods/createOrder', data, 'post');

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new OrderApi();