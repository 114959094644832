<template>
    <Page ref="page">
        <div class="lk" v-if="$store.state.token != null">
            <div class="lk-header">
                <h1>
                    <span>
                        Настройки
                    </span>
                </h1>
                <a class="settings-btn" href="/lk">
                    <span>
                        история
                    </span>
                </a>
            </div>
            <Settings ref="settings"/>
        </div>
        <div v-else class="container logout">
            <span>
                Вы не авторизованы. <br>
                Пожалуйста войдите в аккаунт.
            </span>
            <div class="btn" @click="openAvtoriz">Войти</div>
        </div>
        <SVGMap ref="map" />
        <RForm ref="form" />
    </Page>
 
</template>

<script>
import Page from "@/components/base/Page.vue"
import Settings from "@/components/lk/Settings.vue"
import SVGMap from "@/components/Map.vue"
import RForm from "@/components/Form.vue"

import { mapMutations } from 'vuex'

export default {
    components: {
        Page,
        Settings,
        SVGMap,
        RForm,
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
        openAvtoriz() {
            this.$store.commit('setTypePopup', 'avtoriz')
            this.$store.commit('openPopup', true);
        }
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);
                
                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return ( 
                this.$refs.settings && 
                this.$refs.form && 
                this.$refs.map
            ); 
        }
    }
}
</script>

<style>
.lk {
    padding: 200px 40px;
}

.lk-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lk-header h1 {
    font-family: HelveticaNeueCyrBlack;
    font-size: 60px;
    color: #07395C;
    text-transform: uppercase;
}

.settings-btn {
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 15px 40px;
    border: 2px solid #145C8E;
    border-radius: 20px;
}

.settings-btn span {
    font-size: 20px;
    color: #145C8E;
    margin-top: 6px;
    text-transform: uppercase;
}

.logout {
    display: flex;
    height: 100lvh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 80px;
}

.logout span {
    color: #212121;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 550;
    line-height: 144%;
}

.logout .btn {
    border-radius: 20px;
    background: #145C8E;
    width: 100%;
    max-width: 770px;
    padding: 17px 26px;
    text-align: center;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
}
</style>