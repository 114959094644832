import Api from "@/assets/js/api/index.js";

class HomeApi extends Api {
    async get(data) {
        try {

            console.log(data);
            const result = await this.send('methods/getPresents', data, "POST");
            console.log(result);
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new HomeApi();