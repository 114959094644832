<template>
    <div class="container">
        <div class="data-form">
            <form @submit="changeData" ref="form" action="">
                <div class="reset-inputs">
                    <div class="input-title">
                        <p>Имя</p>
                        <input v-model="name" name = "name" type="">
                    </div>
                    <div class="input-title">
                        <p>Логотип организации</p>
                        <div class="input"> {{ file_name }}
                        <label id="folder-input" for="folder"><img src="@/assets/img/folder-add.png" alt=""></label>
                        <input @change="fileAdd" id="folder" name="" type="file" accept="image/png, image/jpg, image/jpeg">
                        </div>
                    </div>
                    <div class="input-title">
                        <p>Фамилия</p>
                        <input v-model="last_name" name = "last_name"  type="">
                    </div>
                    <div class="logo-reset">
                        <img ref="logotip" src="" alt="">
                    </div>
                    <div class="input-title">
                        <p>Организация</p>
                        <input @change="changeCompany" v-model="company" name="company" type="">
                    </div>
                    <div class="input-title">
                        <p>Номер телефона</p>
                        <input v-model="phone" name="phone" type="">
                    </div>
                    <div class="is-company" :class="is_company_bloced == true ? '' : 'bloced'">
                        <input id="is_company" v-model="is_company" type="checkbox">
                        <label for="is_company">Указывать организацию как дарителя</label>                    
                    </div>
                </div>
                <div class="reset-btn-container">
                    <button class="reset-btn">Cохранить изменения</button>
                    <span class="errorr">{{ error_text }}</span>
                </div>
            </form>
        </div>
        <div class="password-form">
            <form @submit="changePassword" action="">
                <div class="title">Изменение пароля</div>
                <div class="reset-inputs">
                    <div class="input-title password-input">
                        <p>Пароль</p>
                        <input v-model="password" type="password" placeholder="Введите пароль" name="password" id="confirmpassword">
                        <img @click="passwordHidden('confirmpassword')" :class="confirm_password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                        <img @click="passwordHidden('confirmpassword')" :class="confirm_password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                    </div>
                    <div class="input-title password-input">
                        <p>Новый пароль</p>
                        <input v-model="new_password" type="password" placeholder="Введите пароль" name="password" id="setpassword">
                        <img @click="passwordHidden('setpassword')" :class="password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                        <img @click="passwordHidden('setpassword')" :class="password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                    </div>
                    <div class="input-title password-input">
                        <p>Подтвержение пароля</p>
                        <input v-model="second_password" type="password" placeholder="Введите пароль" name="password" id="dublepassword">
                        <img @click="passwordHidden('dublepassword')" :class="duble_password_hidden == true ? 'active' : ''" src="@/assets/img/eye-slash.svg" alt="">
                        <img @click="passwordHidden('dublepassword')" :class="duble_password_hidden == false ? 'active' : ''" src="@/assets/img/eye.svg" alt="">
                    </div>
                </div>
                <div class="reset-btn-container">
                    <button class="reset-btn">Cохранить изменения</button>
                    <span class="errorr">{{ error_text_password }}</span>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

    import { replaceNumberForPaste } from "@/assets/js/helper.js";
    import lk_reset from '@/assets/js/api/reset_lk.js'

    export default {
    name: 'commonPopup',
    components: {

    },
    data() {
        return {
            password_hidden: true,
            confirm_password_hidden: true,
            duble_password_hidden: true,
            phone: '',
            file_name: 'Выберите файл',
            name: '',
            last_name: '',
            company: '',
            is_company: false,
            is_company_bloced: true, 
            password: '',
            new_password:'',
            second_password:'',
            error_text: '',
            error_text_password: '',
            logo_company: null,
            logo_url: null
        }
    },
    methods: {
        passwordHidden(input) {
            if (input == 'confirmpassword') {
                this.confirm_password_hidden = !this.confirm_password_hidden
                if (this.confirm_password_hidden == false) {
                    this.show(input);
                } else {
                    this.hide(input);
                }
            } else if (input == 'setpassword') {
                this.password_hidden = !this.password_hidden
                if (this.password_hidden == false) {
                    this.show(input);
                } else {
                    this.hide(input);
                }
            } else if (input == 'dublepassword') {
                this.duble_password_hidden = !this.duble_password_hidden
                if (this.duble_password_hidden == false) {
                    this.show(input);
                } else {
                    this.hide(input);
                }
            }
        },

        changeCompany() {
            if (this.company != '') {
                this.is_company_bloced = false
            } else {
                this.is_company_bloced = true
            }
        },
        fileAdd() {
            const form = this.$refs.form;
            const input = form.querySelector('#folder');
            const logo = this.$refs.logotip;
            if (input) {
                let fileInput = input.files[0];
                this.logo_company = fileInput
                var reader  = new FileReader();
                reader.onload = function () {
                    logo.src = reader.result  
                };
                reader.readAsDataURL(input.files[0]);
                this.file_name = input.value.split('#')[0].split('?')[0].split('\\').pop()
            }
        },

        async changeData(e) {
            e.preventDefault();
            const data = {
                last_name: this.last_name,
                first_name: this.name,
                phone: this.phone,
                company: this.company,
                file: this.logo_company,
                is_company: this.is_company
            }

            const formdata = new FormData();
            formdata.append("last_name", data.last_name);
            formdata.append("first_name", data.first_name);
            formdata.append("phone", data.phone);
            formdata.append("company", data.company);
            formdata.append("file", data.file);
            formdata.append("is_company", data.is_company);

            if (this.phone.length == 18) {
                const result = await lk_reset.reset(formdata);
                if (result?.success == true) {     
                    this.error_text = ''
                    this.$store.commit('setTypePopup', 'success-data')
                    this.$store.commit('openPopup', true);
                }
            } else {
                this.error_text = 'заполните телефон полностью'
            }
        },

        async changePassword(e) {
            e.preventDefault();
            if (this.new_password == this.second_password) {
                const data = {
                    password: this.password,
                    new_password: this.new_password,
                } 

                const result = await lk_reset.resetPass(data);
                if (result?.message) {
                    this.error_text_password = result.message;
                }   
                if (result?.success == true) {
                    this.$store.commit('setTypePopup', 'success-password')
                    this.$store.commit('openPopup', true);
                    this.error_text_password = ''
                }
            } else {
                this.error_text_password = "Пароли не совпадают"
            }
        },

        async formSubmit(e) {
            e.preventDefault();
            const form = this.$refs.form;
            const inputs = form.querySelectorAll('input');
            const data = {}

            inputs.forEach(element => {
                data[element.name] = element.value;
            })
        },
        show(input) {
            var p = document.getElementById(input);
            p.setAttribute('type', 'text');
        },

        hide(input) {
            var p = document.getElementById(input);
            p.setAttribute('type', 'password');
        },
    },
    watch: {
        phone(new_value) {
            this.phone = replaceNumberForPaste(new_value);
        },
    },
    computed: {

    },
    async mounted() {
        const result = await lk_reset.get();
        this.last_name = result.last_name;
        this.name = result.first_name;
        this.phone = result.fields.phone;
        this.company = result.fields.company;
        this.is_company = result.fields.is_company
        this.file_name = result.fields.logo_title;
        this.$refs.logotip.src = result.fields.logo_company;

        if (this.company != 0) {
            this.is_company_bloced = false
        }
        // this.myFunction();
    }
}
</script>
<style>    
    .data-form,
    .password-form {
        padding-bottom: 40px;
        border-bottom: 1px solid #4A4A4A;
        margin-bottom: 10px;
    }

    .reset-inputs {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 40px;
    }

    .logo-reset {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        height: 110px;
        margin: auto 0px;
    }

    .logo-reset img {
        height: 100%;
    }

    .reset-btn-container {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    input,
    .input {
        border-radius: 20px;
        border: 1px solid #4A4A4A;
        background: #FFF;
        width: 100%;
        padding: 13px 15px;
        color: #212121;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .input {
        position: relative;
        padding-left: 60px;
    }

    .input img {
        position: absolute;
        z-index: 10;
    }

    .input input {
        position: absolute;
        padding: 0;
        opacity: 0px;
        width: 0px;
        height: 0px;
        pointer-events: none;
    }

    .input-title {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 30px;
    }

    .input-title p {
        color: #4A4A4A;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    #folder-input {
        position: absolute;
        top: 10px;
        left: 25px;
        width: 24px;
        height: 25px;
        cursor: pointer;
    }

    .reset-btn {
        border-radius: 20px;
        background: #69A0C7;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 550;
        line-height: 153%;
        text-transform: uppercase;
        border: 1px solid #69A0C7;
        padding: 13px;
        width: 100%;
        max-width: 500px;
        text-align: center;
    }

    .password-input {
        position: relative;
    }

    .password-input img {
        position: absolute;
        bottom: 12px;
        right: 40px;
        transition: 0.4s;
        width: 24px;
        height: 24px;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
    }

    .password-input img.active {
        opacity: 1;
        pointer-events: all;
    }

    .is-company {
        margin-top: auto;
    }

    .is-company label {
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .is-company input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 0px;
        height: 0px;
    }

    .is-company input+label {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .is-company input+label::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 25px;
        border: 2px solid #424242;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }

    .is-company.bloced input:checked+label::before {
        background-image: url("@/assets/img/cheked.svg");
    }

    .errorr {
        color: #8E141B;
        margin-top: 20px;
        font-size: 18px;
    }
</style>