import Api from "@/assets/js/api/index.js";

class ContactsApi extends Api {
    async get() {
        try {
            const result = await this.send('methods/page/contacts');
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new ContactsApi();