import Api from "@/assets/js/api/index.js";

class lk_reset extends Api {
    async get() {
        try {
            const result = await this.send('methods/accountGetData');
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async reset(data) {
        try {
            const result = await this.send('methods/accountUpdate', data, 'POST');
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async resetPass(data) {
        try {
            const result = await this.send('methods/changePassword', data, 'POST');
            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new lk_reset();
