<template>
    <Page ref="page">
        <div class="container privacy-policy">
            <h1>{{ page_data?.title }}</h1>
            <div class="content" v-html="page_data?.content"></div>
        </div>
        <SVGMap ref="map" />
        <RForm ref="form" />
    </Page>
</template>
<script>
import Api from '@/assets/js/api';

import Page from '@/components/base/Page.vue';
import SVGMap from "@/components/Map.vue"
import RForm from "@/components/Form.vue"

import { mapMutations } from 'vuex'

export default {
    components: {
        Page,
        SVGMap,
        RForm,
    },
    data() {
        return {
            page_data: null,
        }
    },
    methods: {
        ...mapMutations({
            setLoading: 'page/setLoading',
        }),
    },
    async mounted() {
        const api = new Api();
        this.page_data = await api.getPrivacyPolicy();

        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);
                
                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return ( 
                this.$refs.map && 
                this.$refs.form
            ); 
        }
    }
}
</script>
<style scoped>
.privacy-policy {
    padding: 120px 20px;
    font-size: 18px;
}

h1, h2 {
    margin-bottom: 50px;
    color: #07395C;
}

</style>