<template>
    <div class="container">
        <div class="product-list" v-if="gifts.length > 0">
            <product v-for="gift in gifts"
                :name="gift.name"
                :age="gift.age"
                :age_month="gift.age_month"
                :gender="gift.gender"
                :is_ordered="gift.is_ordered"
                :text="gift.text"        
                :link="gift.link"        
                :ordered_text="gift.ordered_text"
                :ordered_img="gift.ordered_img"
                :in_catalog="in_catalog"
                :gift_id="gift.gift_id"
                :district = gift.city_title
                :district_img = gift.city_img
            />
        </div>
        <div class="no-product" v-else>
            Подарков не найдено :(
        </div>
    </div>
</template>
    
<script>
import product from '@/components/catalog/product.vue'

export default {
    name: 'catalogList',
    components: {
        product
    },
    props: {
        in_catalog: true,
        gifts: []
    },
    mounted() {
        
    }
}
</script>
<style>

.time {
    display: block;
    margin-top: 50px;
    text-align: right;
}

.product-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 50px;
}

.no-product {
    margin: 200px 0;
    text-align: center;
    color: #145C8E;
    font-size: 36px;
}

@media screen and (max-width: 1710px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1200px) {
    .product-list {
        margin-top: 40px;
    }
}
</style>