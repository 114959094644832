<template>
    <div class="curator-popup" :class="$store.state.curators_popup.is_open ? 'open' : ''">
        <div class="curator-popup-bg" @click="close"></div>
        <div class="curator-popup-content">
            <div class="curator-popup-close" @click="close">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26 1L1 26" stroke="#C10E3F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M26 26L1 1" stroke="#C10E3F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div class="curator-popup-city">
                {{ current?.city }}
            </div>
            <div class="curator-popup-name">
                {{ current?.second_name + ' ' + current?.name }}
            </div>
            <div v-if="current?.phone" class="curator-popup-phone">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71575 23.2843 0 15 0C6.71575 0 0 6.71575 0 15C0 23.2843 6.71575 30 15 30Z" fill="#C10E3F"/>
                    <path d="M12.0973 9H10.2632C9.56554 9 9 9.56554 9 10.2632C9 16.193 13.807 21 19.7368 21C20.4345 21 21 20.4345 21 19.7368V17.9027C21 17.3575 20.6681 16.8672 20.1619 16.6647L18.4047 15.9619C17.95 15.78 17.4322 15.862 17.056 16.1756L16.6021 16.5538C16.0723 16.9953 15.2933 16.96 14.8057 16.4723L13.5277 15.1943C13.04 14.7067 13.0047 13.9277 13.4462 13.3979L13.8244 12.944C14.138 12.5678 14.22 12.05 14.0381 11.5953L13.3353 9.83815C13.1328 9.33193 12.6425 9 12.0973 9Z" fill="white"/>
                </svg> 
                <a :href="'tel:' + current?.phone">{{ current?.phone }}</a>
            </div>
            <div v-if="current?.telegram" class="curator-popup-telegram">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M30 15C30 23.2843 23.2843 30 15 30C6.71575 30 0 23.2843 0 15C0 6.71575 6.71575 0 15 0C23.2843 0 30 6.71575 30 15ZM10.0447 13.6259C8.54206 14.282 6.99819 14.9561 5.58641 15.7337C4.84923 16.2735 5.82899 16.6553 6.74819 17.0134C6.89431 17.0704 7.03888 17.1267 7.17481 17.1829C7.28794 17.2178 7.40294 17.2544 7.5195 17.2915C8.54181 17.6172 9.68169 17.9804 10.6742 17.4341C12.3046 16.4975 13.8431 15.4147 15.3806 14.3328C15.8843 13.9783 16.3878 13.6239 16.8944 13.2748C16.9182 13.2596 16.9449 13.2422 16.9743 13.2233C17.4058 12.9435 18.3765 12.3143 18.0174 13.1813C17.1684 14.1098 16.259 14.9317 15.3447 15.7581C14.7284 16.3151 14.1099 16.8741 13.5062 17.4691C12.9804 17.8963 12.4343 18.7555 13.0231 19.3538C14.3793 20.3032 15.7567 21.2296 17.1333 22.1554C17.5812 22.4567 18.0291 22.7579 18.4761 23.0599C19.2338 23.6648 20.4178 23.1755 20.5844 22.2303C20.6584 21.7955 20.7328 21.3607 20.8072 20.9259C21.2182 18.523 21.6293 16.1192 21.9925 13.7084C22.0419 13.3302 22.0978 12.9521 22.1538 12.5738C22.2895 11.6569 22.4254 10.7388 22.4678 9.81694C22.3584 8.89694 21.2429 9.09925 20.6222 9.30612C17.4314 10.5203 14.2726 11.8244 11.1262 13.1515C10.7697 13.3094 10.4084 13.4671 10.0447 13.6259Z"
                        fill="#C10E3F" />
                </svg>
                <a :href="'https://t.me/' + current?.telegram" target="_blank">@{{ current?.telegram }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'CuratorsPopup',
    methods: {
        close() {
            this.$store.commit('setCuratorsPopup', {
                is_open: false,
                current: this.current,
            });
        }
    },
    computed: {
        ...mapState({
            current: (state) => state.curators_popup.current,
        }),
    }
}
</script>
<style>
.curator-popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 0.8s;
    padding: 20px;
}

.curator-popup.open {
    opacity: 1;
    pointer-events: all;
}

.curator-popup-bg {
    background: rgba(0, 0, 0, 0.20); 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.curator-popup-content {
    position: relative;
    z-index: 10;
    width: 520px;
    padding: 55px 45px;
    border-radius: 36px;
    background: #FFF;
}

.curator-popup-close {
    position: absolute;
    top: 26px;
    right: 40px;
    cursor: pointer;
}

.curator-popup-city {
    color: rgba(0, 0, 0, 0.50); 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; 
    margin-bottom: 10px;
}

.curator-popup-name {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
    margin-bottom: 20px;
}

.curator-popup-phone,
.curator-popup-telegram {
    color: var(--black);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 14px;
}

.curator-popup-phone a,
.curator-popup-telegram a {
    color: var(--black);
}

@media screen and (max-width: 1200px) {
    .curator-popup-name {
        font-size: 20px;
    }

    .curator-popup-phone {
        margin-bottom: 5px;
    }

    .curator-popup-phone a,
    .curator-popup-telegram a {
        font-size: 16px;
    }

    .curator-popup-phone svg,
    .curator-popup-telegram svg {
        height: 26px;
        width: 26px;
    }

    .curator-popup-city {
        font-size: 10px;
    }

    .curator-popup-close {
        top: 20px;
        right: 30px;
    }

    .curator-popup-close svg {
        height: 20px;
        width: 20px;
    }

    .curator-popup-content {
        padding: 30px 20px;
    }
}
</style>